import React, { useEffect, useState } from 'react';
import {
  AppBar, Toolbar, Typography, Box,
  IconButton, Grid, Card, CardContent, CardMedia, Button, Dialog, DialogTitle, DialogContent, CircularProgress
} from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Sudoku from './Games/Sudoku';
import TicTacToe from './Games/TicTacToe';
import SpellBound from './Games/SpellBound'; // Added SpellBound import
import Wordsearch from './Games/Wordsearch'; // Added Wordsearch import
import TimesTablesQuiz from '../TimesTableQuiz/TimesTableQuiz';
import AvatarIcon from '../Avatar/AvatarIcon';
import UserGreeting from '../components/UserGreeting';
import useApi from '../Utils/useApi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RocketFuelManager from './RocketFuelManager'; 




const defaultTheme = createTheme();
const backgroundUrl = 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/Background1.png';
const PlaceholderBackground = '#000';

const Root = styled('div')(({ backgroundLoaded }) => ({
  height: '100vh',
  overflow: 'auto',
  padding: '20px',
  paddingBottom: '100px',
  background: backgroundLoaded
    ? `url(${backgroundUrl}) no-repeat center center`
    : PlaceholderBackground,
  backgroundSize: 'cover',
  transition: 'background 0.5s ease-in-out', // Smooth transition when image loads
}));


const StyledCard = styled(Card)({
  width: 300,
  height: 400,
  margin: '20px',
  background: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Media = styled(CardMedia)({
  height: 200,
  width: 300,
  objectFit: 'cover',
});

const CostText = styled(Typography)({
  color: 'white',
  fontWeight: 'bold',
});

const RewardText = styled(Typography)({
  color: 'white',
});

const PlayButton = styled(Button)({
  backgroundColor: '#ff4081',
  color: 'white',
  '&:hover': {
    backgroundColor: '#ff79b0',
  },
});



// Centralized styling for iframe container
const IframeContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#000',
  overflow: 'hidden',
  position: 'relative',
  paddingBottom: '60px', // Add extra space for the button
});



const Iframe = styled('iframe')({
  width: '100%', // Full container width
  height: '100%', // Full container height
  border: 'none', // No border
  display: 'block', // Prevent inline-block spacing
  objectFit: 'contain', // Ensures the iframe content fits without overflow
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional shadow for aesthetics
});




const LeaderboardButton = styled(Button)({
  backgroundColor: '#1e90ff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#63b8ff',
  },
  marginTop: '10px', // Add some space between buttons
});

const RocketFuelText = styled(Typography)({
  fontSize: '2rem',
  color: '#fff',
  textShadow: '2px 2px 4px #000',
});

const ScrollingText = styled('div')({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: 'black',
  color: 'red',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textAlign: 'center',
  padding: '10px 0',
  position: 'fixed',
  bottom: '0',
  left: '0',
  zIndex: 1,
  '& span': {
    display: 'inline-block',
    paddingLeft: '100%',
    animation: 'scroll 20s linear infinite', // Slowed down to 20 seconds
  },
  '@keyframes scroll': {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
});

const LeaderboardContainer = styled(Box)({
  backgroundColor: 'black',
  color: 'lime',
  padding: '20px',
  textAlign: 'center',
  borderRadius: '10px',
  border: '3px solid magenta',
  maxHeight: '70vh',
  overflowY: 'auto',
});

const LeaderboardEntry = styled(Typography)({
  fontSize: '2rem',
  margin: '10px 0',
  color: 'cyan',
  textShadow: '2px 2px 4px purple',
  fontFamily: '"Press Start 2P", cursive', // Arcade style font
});

const ShadedBackground = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with 50% transparency
  borderRadius: '8px', // Slightly rounded corners for aesthetics
  padding: '16px', // Space inside the shaded box
  margin: '16px 0', // Space around the shaded box
});

export default function RocketArcade() {
  const navigate = useNavigate();
  const api = useApi();
  const [rocketfuel, setRocketfuel] = useState(100); // Initial rocketfuel balance for example
  const [activeGame, setActiveGame] = useState(null);
  const [name, setName] = useState('');
  const access_token = localStorage.getItem('accessToken');
  const user_id = localStorage.getItem('user_id');
  const [messageIndex, setMessageIndex] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]); // Initialize as empty array
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);


  useEffect(() => {
    const img = new Image();
    img.src = backgroundUrl; // Preload the background image
    img.onload = () => setBackgroundLoaded(true); // Update state when the image is loaded
  }, []);
 

  const messages = [
    "Welcome to Rocket Arcade! Earn RocketFuel by completing lessons in the classroom to play!",
    "Check out our new game Mathsteroids, save the city by destroying asteroids and practice your Maths skills!",
    "Don't forget to play Sudoku and Tic-Tac-Toe where you can earn RocketFuel!",
    "New games are added monthly, so keep an eye out for more fun challenges!",
    "We want to hear from you, do you have any cool game ideas that are both fun and educational? Get in touch using the Contact Us button!",
    "You're doing amazing! Keep up the great work!",
    "Every step forward is progress. Keep going!",
    "Believe in yourself, you're capable of more than you know!",
    "Small wins lead to big victories. Celebrate your achievements!",
    "Challenge yourself today, you might surprise yourself!",
    "Learning is an adventure—enjoy the journey!",
    "Mistakes are proof that you're trying, keep pushing!",
    "Your efforts today build a better tomorrow!",
    "Stay curious, and keep exploring new challenges!",
    "You're a rockstar at Rocket Arcade! Keep shining!",
    "Confidence comes from practice. You're doing great!",
    "Persistence is the key to success. Don’t give up!",
    "Learning new things makes you unstoppable!",
    "Every game you play makes you sharper and stronger!",
    "You’ve got this! Keep moving forward with a smile!",
    "Your hard work today will make tomorrow easier!",
    "Success comes to those who keep trying!",
    "You're learning something new every day—how cool is that?",
    "Keep challenging yourself—there's always something new to discover!",
    "You're braver than you think and stronger than you know!",
    "The more you learn, the more doors you open to exciting adventures!",
    "Believe in your power to make great things happen!",
    "Keep aiming for the stars—you can reach them!",
    "Practice makes progress, and you're making lots of it!",
    "Your unique ideas make the world a more exciting place!",
    "Did you know octopuses have three hearts and blue blood?",
    "A group of flamingos is called a 'flamboyance'!",
    "Bananas are berries, but strawberries aren't!",
    "Honey never spoils—you can eat honey that’s thousands of years old!",
    "Sharks existed before trees did—by about 50 million years!",
    "The Eiffel Tower can be 15 cm taller during the summer due to heat expansion!",
    "There are more stars in the universe than grains of sand on all Earth's beaches!",
    "Butterflies can taste with their feet!",
    "A single strand of spider silk is thinner than a human hair but five times stronger than steel!",
    "Koalas sleep for up to 22 hours a day!",
    "The shortest war in history lasted only 38 to 45 minutes!",
    "Sloths can take up to a month to digest a single meal!",
    "Your body contains enough carbon to make 9,000 pencils!",
    "A day on Venus is longer than a year on Venus!",
    "Dolphins have names for each other and call out to friends!",
    "Ants don’t have ears, but they can 'hear' through vibrations in the ground!",
    "A group of porcupines is called a 'prickle'!",
    "There’s enough water in Lake Superior to cover all of North and South America in one foot of water!",
    "Cows have best friends and can get stressed when they’re apart!",
    "Elephants are the only animals that can’t jump!",
    "Some frogs can freeze solid and then thaw back to life!",
    "Ostriches can run faster than horses!",
    "If you could fly a plane to Pluto, it would take about 800 years!",
    "A bolt of lightning is five times hotter than the surface of the sun!",
    "An astronaut’s height can increase by about 2 inches while in space!",
    "The human nose can detect about 1 trillion different scents!",
    "The largest snowflake ever recorded was 15 inches wide!",
    "Some jellyfish are immortal—they can revert to their baby state and grow again!",
    "The world’s smallest reptile is a tiny chameleon, only about the size of a sunflower seed!",
    "A giraffe's tongue can be up to 20 inches long!",
    "Penguins can jump as high as 6 feet in the air!",
    "Bees can recognize human faces!",
    "Some fish can actually walk on land, like the mudskipper!",
    "There are more species of beetles than any other animal on Earth!",
    "The longest hiccuping spree lasted 68 years!",
    "Cats have 32 muscles in each ear!",
    "The heart of a blue whale weighs as much as a small car!",
    "Pineapples take about two years to grow!",
    "The largest living organism is a fungus in Oregon that covers 2,385 acres!",
    "Sea otters hold hands while sleeping to keep from drifting apart!",
    "An eagle's eyesight is about 4 to 8 times stronger than a human's!",
    "A rhinoceros’s horn is made of hair!",
    "The Empire State Building has its own zip code!",
    "A snail can sleep for up to three years!",
    "Tigers have striped skin, not just striped fur!",
    "Dragonflies can fly in all six directions—up, down, forward, backward, left, and right!",
    "The Mona Lisa has no eyebrows—they were shaved off in the Renaissance fashion of the time!"
  ];
  

  useEffect(() => {
    if (user_id && access_token) {
      // Fetch the user's name
      api.get(`/user/getname/${user_id}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        if (response.data.firstname) {
          setName(response.data.firstname);
        }
      })
      .catch(error => console.log(error));

      // Fetch the user's RocketFuel amount
      api.get(`/user/get_rocketfuel/${user_id}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        if (response.data.amount !== undefined) {
          setRocketfuel(response.data.amount);
        }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false)); // Hide loading screen after data is fetched
    }
  }, [user_id, access_token, api]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(prevIndex => (prevIndex + 1) % messages.length);
    }, 30000); // Change message every 30 seconds
    return () => clearInterval(interval);
  }, [messages.length]);

  const handlePlay = (game) => {
    if (rocketfuel >= game.cost) {
        setLoading(true); // Show the spinner

        api.post('/user/launch_game', {
            game_id: game.id,
            cost: game.cost,
        }, {
            headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((response) => {
            if (response.status === 200) {
                setRocketfuel(response.data.remaining_rf); // Deduct RocketFuel
                setActiveGame(game); // Set the active game
                setLoading(false); // Hide the spinner when the game is ready
            }
        })
        .catch((error) => {
            alert('Error launching game!');
            console.log(error);
            setLoading(false); // Ensure the spinner hides even on error
        });
    } else {
        alert('Not enough RocketFuel!');
    }
};


  const fetchLeaderboard = (game) => {
    let endpoint;
    if (game.name === 'Mathsteroids') {
      endpoint = '/games/leaderboard';
    } else if (game.name === 'SpellBound') {
      endpoint = '/games/spellbound/leaderboard';
    }

    api.get(endpoint, { headers: { Authorization: `Bearer ${access_token}` } })
      .then(response => {
        console.log('Leaderboard response:', response.data); // Log the response for debugging
        if (response.status === 200 && Array.isArray(response.data.leaderboard)) {
          setLeaderboard(response.data.leaderboard);
          setShowLeaderboard(true);
        } else {
          setLeaderboard([]); // Ensure leaderboard is an array
        }
      })
      .catch(error => {
        console.log(error);
        setLeaderboard([]); // Ensure leaderboard is an array in case of error
      });
  };

  const games = [
    { id: 1, name: 'Sudoku', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/sudokulogo.png', component: <Sudoku onExit={() => setActiveGame(null)} />, type: 'phaser', rewards: { easy: 10, medium: 25, hard: 50 }, category: 'reward'},
    { id: 2, name: 'Tic-Tac-Toe', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/tictactoelogo.png', component: <TicTacToe onExit={() => setActiveGame(null)} />, type: 'phaser', rewards: { easy: 10, medium: 20, hard: 40 }, category: 'reward'},
    { id: 3, name: 'Mathsteroids', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/MathsteroidsLogo.png', type: 'iframe', url: 'https://rl-mathsteroids.ams3.digitaloceanspaces.com/index.html', rewards: { score: "1 RocketFuel for every 10 points" }, category: 'reward'},
    { id: 4, name: 'Times Table Rocket Launch', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/timestablequizlogo.png', component: <TimesTablesQuiz onExit={() => setActiveGame(null)} />, type: 'phaser', rewards: { score: "5 RocketFuel for every correct answer" }, category: 'reward'},
    { id: 5, name: 'SpellBound', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/SpellBoundLogo.png', component: <SpellBound onExit={() => setActiveGame(null)} />, type: 'phaser', rewards: { score: "10 RocketFuel for every correct word" }, category: 'reward'},
    { id: 6, name: 'Wordsearch', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/Wordsearchlogo.png', component: <Wordsearch onExit={() => setActiveGame(null)} />, type: 'phaser', rewards: { score: "20 Rocketfuel" }, category: 'reward'},
    { id: 7, name: 'Number Pop', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/NumpopLogo.png', type: 'iframe', url: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/Games/Numpop/NumPop/index.html', rewards: { score: "No Rewards" }, category: 'justForFun' },
    { id: 8, name: 'Stickman Parkour', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/StickmanParkourLogo.png', type: 'iframe', url: 'https://cloud.onlinegames.io/games/2024/construct/219/stickman-parkour/index-og.html', rewards: { score: "No Rewards" }, category: 'justForFun' },
    { id: 9, name: 'Hero Rush Tower Defense', cost: 10, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/HeroRushLogo.png', type: 'iframe', url: 'https://www.onlinegames.io/games/2023/unity/hero-rush-tower-defense/index.html', rewards: { score: "No Rewards" }, category: 'justForFun' },
    { id: 10, name: 'Bloons Tower Defense 4', cost: 25, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/BloonsBlasterLogo.png', type: 'iframe', url: 'https://www.addictinggames.com/embed/html5-games/20543', rewards: { score: "No Rewards" }, category: 'justForFun' },
    { id: 11, name: 'Bubble Spinner', cost: 25, image: 'https://rlarcade.ams3.cdn.digitaloceanspaces.com/BubbleSpinner.png', type: 'iframe', url: 'https://www.addictinggames.com/embed/html5-games/19016', rewards: { score: "No Rewards" }, category: 'justForFun' },
  ];
  

  

  const rewardGames = games.filter((game) => game.category === 'reward');
  const justForFunGames = games.filter((game) => game.category === 'justForFun');


  const SectionTitle = styled(Typography)({
    color: 'white',
    textShadow: '1px 1px 2px black, -1px -1px 2px black', // Black stroke effect
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/LessonSelect')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Rocket Arcade
          </Typography>
          <Box flexGrow={1} />
          <UserGreeting />
          <IconButton onClick={() => navigate('/Avatar')} sx={{ ml: 2 }}>
            <AvatarIcon accessToken={access_token} />
          </IconButton>
        </Toolbar>
      </AppBar>
  
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          flexDirection="column"
          bgcolor="black"
        >
          <CircularProgress color="secondary" />
          <Typography variant="h6" color="white" sx={{ mt: 2 }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <Root backgroundLoaded={backgroundLoaded}>
          <RocketFuelManager />
            <Box align="center" mb={4}>
            <LazyLoadImage
  src="https://rlarcade.ams3.cdn.digitaloceanspaces.com/RocketArcadeLogo.png"
  alt="Rocket Arcade Logo"
  effect="blur"
  style={{ height: '500px', display: 'block', margin: '0 auto' }}
/>

            </Box>
            <ShadedBackground>
            <RocketFuelText variant="h5" align="center">
              RocketFuel Balance: {rocketfuel}
            </RocketFuelText>
            </ShadedBackground>
  
            {loading ? (
    // Show the loading spinner
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="black"
    >
        <CircularProgress color="secondary" />
        <Typography variant="h6" color="white" sx={{ mt: 2 }}>
            Loading {activeGame?.name}...
        </Typography>
    </Box>
) : activeGame ? (
    // Show the game (iframe or component) once loading is complete
    <IframeContainer>
        {activeGame.type === 'iframe' ? (
            <Iframe
                src={`${activeGame.url}?userId=${user_id}&accessToken=${access_token}`}
                title={activeGame.name}
                allowFullScreen
            />
        ) : (
            activeGame.component
        )}
        <PlayButton
            onClick={() => setActiveGame(null)}
            sx={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#ff4081',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '5px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            Return to Arcade
        </PlayButton>
    </IframeContainer>
) : (
    // Show the default arcade interface
    <>
    <ShadedBackground>
        <SectionTitle variant="h4">
            Reward Games - Earn RocketFuel and Learn!
        </SectionTitle>
        <Grid container justifyContent="center">
            {rewardGames.map((game) => (
                <Grid item key={game.id}>
                    <StyledCard>
                        <LazyLoadImage
                            src={game.image}
                            alt={game.name}
                            effect="blur"
                            style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                        />
                        <CardContent>
                            <Typography variant="h5">{game.name}</Typography>
                            <CostText variant="body2">Cost: {game.cost} RocketFuel</CostText>
                            <RewardText variant="body2">
                                {game.rewards.easy !== undefined ? (
                                    <>
                                        Rewards: Easy {game.rewards.easy}, Medium {game.rewards.medium}, Hard {game.rewards.hard} RocketFuel
                                    </>
                                ) : (
                                    <>Reward: {game.rewards.score}</>
                                )}
                            </RewardText>
                            <PlayButton
                                variant="contained"
                                fullWidth
                                onClick={() => handlePlay(game)}
                            >
                                Play
                            </PlayButton>
  </CardContent>
</StyledCard>

    </Grid>
  ))}
</Grid>
</ShadedBackground>
  
                <Box my={4}>
                  <hr style={{ border: '1px solid white', margin: '20px 0' }} />
                </Box>
                <ShadedBackground>
                <SectionTitle variant="h4">
  Just for Fun - Third Party Games
</SectionTitle>
<Box my={2} textAlign="center">
<Typography variant="h6" style={{ color: 'white', marginBottom: '16px' }}>
  These games are purely for fun! They cost RocketFuel to play but offer no rewards.
  If you run out of RocketFuel, complete lessons in the{' '}
  <Button
    variant="contained"
    color="success"
    size="small"
    onClick={() => navigate('/LessonSelect')}
    style={{ fontWeight: 'bold', marginLeft: '8px' }}
  >
    Classroom
  </Button>{' '}
  to earn more!
</Typography>
  <Typography variant="body2" style={{ color: 'grey', fontStyle: 'italic', marginTop: '8px' }}>
    Disclaimer: These games are provided by third-party developers and may include advertisements.
  </Typography>
</Box>
<Grid container justifyContent="center">
  {justForFunGames.map((game) => (
    <Grid item key={game.id}>
      <StyledCard>
        <Media image={game.image} title={game.name} />
        <CardContent>
          <Typography variant="h5">{game.name}</Typography>
          <CostText variant="body2">Cost: {game.cost} RocketFuel</CostText>
          <Typography variant="body2" style={{ color: 'yellow' }}>
            No Rewards—Just for Fun!
          </Typography>
          <PlayButton
            variant="contained"
            fullWidth
            onClick={() => handlePlay(game)}
          >
            Play
          </PlayButton>
        </CardContent>
      </StyledCard>
    </Grid>
  ))}
</Grid>
</ShadedBackground>

              </>
            )}
          </Root>
  
          <ScrollingText>
            <span>{messages[messageIndex]}</span>
          </ScrollingText>
          {/* Add the Dialog here */}
<Dialog open={showLeaderboard} onClose={() => setShowLeaderboard(false)}>
  <DialogTitle style={{ color: 'magenta', textAlign: 'center', fontFamily: '"Press Start 2P", cursive' }}>
    Leaderboard
  </DialogTitle>
  <DialogContent>
    <LeaderboardContainer>
      {leaderboard.length === 0 ? (
        <Typography style={{ color: 'white' }}>No scores available.</Typography>
      ) : (
        leaderboard.map((entry, index) => (
          <LeaderboardEntry key={index}>
            {index + 1}. <strong>{entry.username}</strong>: {entry.score}
          </LeaderboardEntry>
        ))
      )}
    </LeaderboardContainer>
  </DialogContent>
</Dialog>
        </>
      )}
    </ThemeProvider>
  ); }