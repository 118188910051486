import Phaser from 'phaser';

class BootScene extends Phaser.Scene {
  constructor() {
    super({ key: 'BootScene' });
  }

  preload() {
    console.log('BootScene preload');

    // Preload the JSON data
    this.load.json('words', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/words.json');

    // Preload the main menu music and sound effects
    this.load.audio('mainMenuMusic', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/audio/MainMenu.mp3');
    this.load.audio('lexicaSpellSound', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/audio/mixkit-fireball-spell-1347.wav');

    // Preload the intro scene images
    for (let i = 1; i <= 7; i++) {
      this.load.image(`intro${i}`, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/introscene${i}.png`);
    }

    // Preload the outro scene images
    for (let i = 1; i <= 3; i++) {
      this.load.image(`victory${i}`, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/outroscene${i}.png`);
    }

    // Preload the main menu background image
    this.load.image('mainMenuBG', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/MainMenuBG.png');
  }

  create() {
    // Initialize settings
    this.registry.set({
      musicEnabled: true,
      sfxEnabled: true,
    });

    console.log('All assets loaded');

    // Emit the custom event to notify that the game is ready
    this.game.events.emit('gameReady');

    this.scene.start('MainMenuScene');
  }
}

export default BootScene;
