import React, { useEffect, useState } from 'react';
import useApi from '../Utils/useApi';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Table, TableCell,
TableContainer, TableBody, Paper, TableHead, TableRow } from '@mui/material';
import NotificationIcon from './NotificationIcon';
import UserGreeting from './UserGreeting';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const ParentDashboard = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [studentProgress, setStudentProgress] = useState({});
  const parent_id = localStorage.getItem('user_id');
  const access_token = localStorage.getItem('accessToken');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [timeFrame, setTimeFrame] = useState('7');
  const [weeklyLessonMetrics, setWeeklyLessonMetrics] = useState({});
  const [weeklyAvgScores, setWeeklyAvgScores] = useState({});
  const [error, setError] = useState(null);
  const [studentUsername, setStudentUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [selectedLesson, setSelectedLesson] = useState(null);
  const api = useApi();
  const [leagueData, setLeagueData] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const subjectMapping = {
    '1': { title: 'Maths', color: '#0303fd' },
    '2': { title: 'Science', color: '#00b333' },
    '3': { title: 'Geography', color: '#de8d00' },
    '4': { title: 'History', color: '#ba00b3' },
    '5': { title: 'English', color: '#c10000' },
    '6': { title: 'ICT', color: '#848484' },
    '7': { title: 'Business Studies', color: '#848484' },    
  };
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionChange = (subject) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? subject : false);
  };

  const handleRowClick = (param) => {
    setSelectedLesson(param.row);
  };

  const handleClose = () => {
    setSelectedLesson(null);
  };

  const handleToggleAutoRenew = (subscriptionId) => {
    const subscription = subscriptions.find(sub => sub.id === subscriptionId);
  
    if (subscription && subscription.recurring) {
      // Only proceed if auto-renew is currently enabled
      api.post(`/payment/subscription/${subscriptionId}/toggleautorenew`, {}, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      .then(response => {
        alert(response.data.message);  // Show success message
      })
      .catch(err => {
        console.error("Error toggling auto-renew:", err);
        alert("Error toggling auto-renew. Please try again.");
      });
    } else {
      // Auto-renew is already disabled, display a message if needed
      alert("Auto-renew is already disabled for this subscription, due to payment laws a new subscription with Auto Renew enabled is required.  Use the 'Add Subscription' button to set this up.");
    }
  };

      // Fetch students and their chat settings
      useEffect(() => {
        api.get(`/user/students/${parent_id}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then(res => {
            setStudents(res.data);
            if (res.data.length > 0) {
                setSelectedStudent(res.data[0].id);
                setChatEnabled(res.data[0].chat_enabled);
            }
        })
        .catch(err => console.log(err));
    }, [parent_id, access_token]);

    // Update chat setting for the selected student
    const handleToggleChat = () => {
        api.put(`/user/chat-settings/${selectedStudent}`, {
            chat_enabled: !chatEnabled
        }, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then(res => {
            alert(res.data.msg);
            setChatEnabled(!chatEnabled);  // Toggle chatEnabled state
        })
        .catch(err => {
            console.error("Error updating chat settings:", err);
            alert("Failed to update chat settings.");
        });
    };

  useEffect(() => {
    setNewUsername("");
    setNewPassword("");
  }, [selectedStudent]);

  useEffect(() => {
    const student = students.find(s => s.id === selectedStudent);
    if (student) {
        setStudentUsername(student.username);
    }
  }, [selectedStudent, students]);

  const handleUpdateCredentials = () => {
    if (!newUsername && !newPassword) {
        setError("Please enter a new username or password to update.");
        return;
    }

    api.post(`/user/student/${selectedStudent}/update`, {
        username: newUsername,
        password: newPassword
    }, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then(res => {
        if (res.data && res.data.msg) {
            alert(res.data.msg);
            if (newUsername) {
                setStudentUsername(newUsername);  // Update the displayed username
                setNewUsername("");  // Clear the input field
            }
            setNewPassword("");  // Clear the password input field
        }
    })
    .catch(err => {
        // Check if there's a response from the server with a message
        if (err.response && err.response.data && err.response.data.msg) {
            setError(err.response.data.msg);  // Display the error message from the server
        } else {
            setError("An unexpected error occurred.");
        }
        console.log(err);
    });
  };

  useEffect(() => {
    if (selectedStudent) {
        api.get(`/subscription/subscription/user/${selectedStudent}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then(response => {
            setSubscriptions(response.data);
        })
        .catch(error => {
            console.error('Error fetching subscription data:', error.message);
        });
    }
  }, [selectedStudent, access_token]);

  useEffect(() => {
    if (selectedStudent) {
      const student = students.find(s => s.id === selectedStudent);
      if (student) {
        console.log("Updating chatEnabled state:", student.chat_enabled);
        setChatEnabled(student.chat_enabled);
        setStudentUsername(student.username);
      }
    }
  }, [selectedStudent, students]);
  

  useEffect(() => {
    let isMounted = true; // to track whether the component is mounted

    if (selectedStudent) {
        api.get(`/rfleague/get-student-current-league/${selectedStudent}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then(res => {
            if (isMounted) { // only update state if component is still mounted
                setLeagueData(res.data);
            }
        })
        .catch(err => {
            console.log(err);
            if (isMounted) {
                setLeagueData(null);
            }
        });
    }




    
    return () => {
        isMounted = false; // cleanup: mark the component as unmounted
    };
  }, [selectedStudent, access_token]);

  useEffect(() => {
    if (!access_token) {
        return;
    }

    // Fetch list of students for the parent
    api.get(`/user/students/${parent_id}`, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then(res => {
        setStudents(res.data);
        
        // Set the first student as the selected student by default
        if (res.data && res.data.length > 0 && !selectedStudent) {
            setSelectedStudent(res.data[0].id);
            setStudentUsername(res.data[0].username);
        }
    })
    .catch(err => console.log(err));
  }, [parent_id, access_token]);

  useEffect(() => {
    if (!access_token || !selectedStudent) {
        return;
    }

    // Fetch progress data for the selected student based on the current time frame
    api.get(`/progress/student-progress/${selectedStudent}?days=${timeFrame}`, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then(res => {
        if (res.data) {
            // Convert "Completed Time" to JavaScript Date objects for easy sorting
            for (let subject in res.data) {
              res.data[subject].sort((a, b) => new Date(b['Completed Time']) - new Date(a['Completed Time']));  // Sort by Completed Time in descending order
            }
        }
        
        setStudentProgress(prevState => ({
            ...prevState,
            [selectedStudent]: res.data
        }));
      })
      .catch(err => {
          console.error("Error fetching student progress:", err);
    });
  }, [selectedStudent, timeFrame, access_token]);

  useEffect(() => {
      if (!access_token) {
          return;
      }

    // Fetching weekly lesson metrics
    api.get('/metrics/dashboard/dayweekcount', {
      headers: {
          'Authorization': `Bearer ${access_token}`
      }
    })
    .then(response => {
        const data = response.data;
        setWeeklyLessonMetrics(data);
    })
    .catch(error => {
        console.error('Error fetching weekly lesson metrics:', error.message);
    });

    // Fetching weekly average scores
    api.get('/metrics/dashboard/avgscores', {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then(response => {
        const data = response.data;  // Extract the data from the response
        setWeeklyAvgScores(data);
    })
    .catch(error => {
        console.error('Error fetching weekly average scores:', error.message);
    });
  }, [access_token]);

  const selectedStudentAvgScores = weeklyAvgScores[selectedStudent] || {};
  const avgScoresData = Object.keys(selectedStudentAvgScores).map(date => {
    return {
      name: date,
      ...selectedStudentAvgScores[date]
    };
  });

  const selectedStudentMetrics = weeklyLessonMetrics[selectedStudent] || {};
  const formattedData = Object.keys(selectedStudentMetrics).map(date => {
    return {
      name: date,
      ...selectedStudentMetrics[date]
    };
  });

  const allSubjects = new Set();
  Object.values(selectedStudentMetrics).forEach(subjects => {
    Object.keys(subjects).forEach(subject => allSubjects.add(subject));
  });

  const LeagueBox = () => {
    if (!leagueData) return null; // don't render anything if there's no league data

    return (
      <Box style={{ border: '1px solid #ccc', padding: '20px', marginRight: '335px', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom align="center">
          {studentUsername}'s League
        </Typography>
        <Typography variant="h6" gutterBottom align="center">
          {leagueData.league_name}
        </Typography>
        <img src={leagueData.imgURL} alt={leagueData.league_name} style={{ maxWidth: '100%' }} />
      </Box>
    );
  };

  const handleAddSubscription = () => {
    const studentDetails = students.find(student => student.id === selectedStudent);
    if (studentDetails) {
      navigate('/checkout', { state: { student: studentDetails } });
    } else {
      alert('Please select a student first.');
    }
  };

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const handleRegister = () => {
    window.location.href = "/StudentRegistration";
  };

  const handleLogin = () => {
    window.location.href = "/ParentLogin"; 
  };

  if (!access_token) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back">
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Parent Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Please log in to see the parent dashboard
            </Typography>
            <Button variant="contained" onClick={handleLogin}>Log in</Button>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar
        position="static" // Changed from "relative" to "static" to match the other AppBar
        color="default" // Set to "default" to get the grey color
        elevation={0} // Set to 0 to remove the shadow for a flat appearance
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }} // Adding the borderBottom style if needed
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => { console.log("Clicked!"); navigate('/RLBlog'); }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Parent Dashboard
          </Typography>
          <Box flexGrow={1}></Box> {/* This pushes the subsequent content to the right */}
          <Box mr={2}> {/* Applying margin to the right of the button */}
            <Button color="success" variant="contained" onClick={handleRegister}> {/* Changed to "outlined" and "inherit" color */}
              Register New Student
            </Button>
          </Box>
          <UserGreeting />
        </Toolbar>
      </AppBar> 
      <Grid container spacing={3} style={{ padding: '20px' }}>
        {/* Student selection sidebar */}
        <Grid item xs={3}>
          <Typography variant="h6" align="center" gutterBottom>
            Select Student
          </Typography>
          <List>
            {students.map((student) => (
              <ListItem 
                button 
                onClick={() => setSelectedStudent(student.id)} 
                key={student.id}
                selected={selectedStudent === student.id}
              >
                <ListItemText primary={`${student.firstname} ${student.lastname}`} />
              </ListItem>
            ))}
          </List>
        </Grid>
  
        {/* Data area for the selected student */}
        <Grid item xs={9}>
          {/* Subscription section */}
          <Box mb={3} bgcolor="#f5f5f5" borderRadius="8px" p={2}> {/* Styled Box */}
            <Typography variant="h6" gutterBottom>Subscription</Typography>
            {subscriptions.length === 0 ? (
              <Typography variant="body1">No subscriptions found for the selected student.</Typography>
            ) : (
              <Box maxWidth="60%"> {/* Restricting the width of the entire table container */}
                <TableContainer component={Paper}>
                  <Table size="small"> {/* Making the table more compact */}
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '20%' }}>Type</TableCell>
                        <TableCell style={{ width: '20%' }}>Start Date</TableCell>
                        <TableCell style={{ width: '20%' }}>Expiry Date</TableCell>
                        <TableCell style={{ width: '20%' }}>Status</TableCell>
                        <TableCell style={{ width: '20%' }}>Auto Renew</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriptions.map(sub => (
                        <TableRow key={sub.id}>
                          <TableCell>{sub.subscription_type}</TableCell>
                          <TableCell>{new Date(sub.start_date).toLocaleDateString()}</TableCell>
                          <TableCell>{new Date(sub.end_date).toLocaleDateString()}</TableCell>
                          <TableCell>{sub.status}</TableCell>
                          <TableCell>{sub.recurring ? "Yes" : "No"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
<Box display="flex" gap={2} mt={2}>
  <Button variant="contained" onClick={handleAddSubscription}>Add Subscription</Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => handleToggleAutoRenew(subscriptions[0]?.id)}  // Assuming you only toggle the first subscription for simplicity
  >
    Turn Off Auto-Renew
  </Button>
</Box>
          </Box>

{/* Chat Settings for the selected student */}
<Box mb={3} bgcolor="#f5f5f5" borderRadius="8px" p={2}> {/* Styled Box */}
  <Typography variant="h6" gutterBottom>
    Chat Settings for {studentUsername || 'Selected Student'}
  </Typography>
  <Typography variant="body1" gutterBottom>
    Chat is currently {chatEnabled ? 'Enabled' : 'Disabled'}.
  </Typography>
  <Button
    variant="contained"
    color={chatEnabled ? 'secondary' : 'primary'}
    onClick={handleToggleChat}
  >
    {chatEnabled ? 'Disable Chat' : 'Enable Chat'}
  </Button>
</Box>

            {/* End of chat settings section */}
  
          {/* Combined section for credentials and league information */}
          <Box mb={3} bgcolor="#f5f5f5" borderRadius="8px" p={2} display="flex" justifyContent="space-between"> {/* Main container Box */}
            {/* Change Username & Password section */}
            <Box bgcolor="#f5f5f5" borderRadius="8px" p={2} flexGrow={1} mr={2}> {/* Adjusted Box */}
              <Typography variant="h6" gutterBottom>Change Username & Password</Typography>
              <Box mt={2}>
                <Typography variant="subtitle1" gutterBottom>Current Username: {studentUsername}</Typography>
                {/* New Username Input */}
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="text"
                  label="Enter New Username"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                />
                {/* New Password Input */}
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="password"
                  label="Enter New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleUpdateCredentials} style={{ marginTop: '16px' }}>
                  Update
                </Button>
                {/* Error Display */}
                {error && (
                  <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
  
            {/* LeagueBox section */}
            <Box bgcolor="#f5f5f5" borderRadius="8px" p={2}> {/* Adjusted Box */}
              <LeagueBox />
            </Box>
          </Box>
  
          {/* Graphs section */}
          <Box display="flex"  bgcolor="#f5f5f5" borderRadius="8px" justifyContent="space-between" mb={3}>
            <Box width="48%">
              <Typography variant="h6" gutterBottom>Lessons by Subject: Last 7 Days</Typography>
              <BarChart width={600} height={300} data={formattedData} margin={{top: 5, right: 5, left: 1, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip formatter={(value, name) => [value, subjectMapping[name]?.title || name]} />
                <Legend formatter={value => subjectMapping[value]?.title || value} />
                {[...allSubjects].map(subjectID => (
                  <Bar 
                    key={subjectID} 
                    dataKey={subjectID} 
                    name={subjectMapping[subjectID]?.title || subjectID}
                    fill={subjectMapping[subjectID]?.color || '#8884d8'} 
                  />
                ))}
              </BarChart>
            </Box>
            <Box width="48%">
              <Typography variant="h6" gutterBottom>Average Scores: Last 7 Days</Typography>
              <BarChart width={600} height={300} data={avgScoresData} margin={{top: 5, right: 30, left: 5, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} domain={[0, 100]} />
                <Tooltip formatter={(value, name) => [value, subjectMapping[name]?.title || name]} />
                <Legend formatter={value => subjectMapping[value]?.title || value} />
                {[...allSubjects].map(subjectID => (
                  <Bar 
                    key={subjectID} 
                    dataKey={subjectID} 
                    name={subjectMapping[subjectID]?.title || subjectID}
                    fill={subjectMapping[subjectID]?.color || '#8884d8'} 
                  />
                ))}
              </BarChart>
            </Box>
          </Box>
  
          {/* Lessons section */}
          <Box my={2} mb={9}>
            <Box my={2} mb={3}>
              <Typography variant="h6" gutterBottom>Completed Lessons</Typography>
              <Typography variant="h7" gutterBottom>(Click a row for more information about the lesson completed.)</Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body1">Select Time Frame:</Typography>
              <Select value={timeFrame} onChange={handleTimeFrameChange}>
                <MenuItem value={'7'}>Last 7 Days</MenuItem>
                <MenuItem value={'30'}>Last 30 Days</MenuItem>
              </Select>
            </Box>
  
            {studentProgress[selectedStudent] && (
              <div style={{ width: '80%' }}>
                {Object.keys(studentProgress[selectedStudent]).map(subject => (
                  <Accordion 
                    key={subject} 
                    onChange={handleAccordionChange(subject)}
                    style={{ marginBottom: '10px' }}
                  >
                    <AccordionSummary 
                      expandIcon={<ExpandMoreIcon />} 
                      aria-controls="panel-content" 
                      id="panel-header"
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <Typography variant="h6" gutterBottom>{subject}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {expandedAccordion === subject && (
                        <DataGrid 
                          autoHeight 
                          rowHeight={80}
                          rows={studentProgress[selectedStudent][subject]} 
                          columns={[
                            { field: 'Completed Time', headerName: 'Completed On', flex: 1 },
                            { field: 'Topic', headerName: 'Topic', flex: 1 },
                            { field: 'Lesson Name', headerName: 'Lesson Name', flex: 1 },
                          ]}
                          onRowClick={handleRowClick}
                          rowClassName="clickable-row"
                          pageSize={10} 
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
  
      {/* Dialog for showing detailed lesson information */}
      {selectedLesson && (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>{selectedLesson['Lesson Name']}</DialogTitle>
          <DialogContent>
            <DialogContentText><strong>Completed On:</strong> {selectedLesson['Completed Time']}</DialogContentText>
            <DialogContentText><strong>Topic:</strong> {selectedLesson['Topic']}</DialogContentText>
            <DialogContentText><strong>Lesson Status:</strong> {selectedLesson['Lesson Status']}</DialogContentText>
            <DialogContentText><strong>Summary:</strong> {selectedLesson['Summary']}</DialogContentText>
            <DialogContentText><strong>Score:</strong> {selectedLesson['Score']}</DialogContentText>
            {/* Add any other fields as needed */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </ThemeProvider>
  );
}

export default ParentDashboard;
