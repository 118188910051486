export const setCache = (key, data, expirationMinutes = 10) => {
    const expiration = new Date().getTime() + expirationMinutes * 60 * 1000;
    localStorage.setItem(key, JSON.stringify({ data, expiration }));
  };
  
  export const getCache = (key) => {
    const cached = localStorage.getItem(key);
    if (!cached) return null;
    const { data, expiration } = JSON.parse(cached);
    if (new Date().getTime() > expiration) {
      localStorage.removeItem(key);
      return null;
    }
    return data;
  };
  