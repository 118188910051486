import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../Utils/useApi';

const defaultTheme = createTheme();

const BusinessStudies = () => {
  const navigate = useNavigate();

  // Setup a response interceptor - This will redirect to login if we get a 401 response code
  axios.interceptors.response.use(
    response => {
      // If the response is successful, just return it
      return response;
    },
    error => {
      // If there's an error in the response, check the status code
      if (error.response && error.response.status === 401) {
        // If it's a 401 (Unauthorized), redirect to login
        navigate('/StudentLogin');
      }
      return Promise.reject(error);
    }
  );

  const student_id = localStorage.getItem('user_id');
  const student_year = localStorage.getItem('student_year');
  const access_token = localStorage.getItem('accessToken');
  const [selectedYear, setSelectedYear] = useState(student_year);
  // State to store the lessons for each subtopic
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  // State to track loading state
  const [loading, setLoading] = useState(false);
  // State to track errors
  const [error, setError] = useState(null);
  const [completedLessons, setCompletedLessons] = useState([]);
  const api = useApi();

  const handleLogin = () => {
    navigate('/StudentLogin');
  };

  // IMPORTANT! SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6, Business Studies=7)
  // API Call to set the selected year in the dropdown for Business Studies(subjectID7) to the same year as the last lesson completed.
  useEffect(() => {
    if (!access_token) return;

    const fetchLastCompletedYear = async () => {
      try {
        const response = await api.get(`/progress/student/${student_id}/last_completed_year_for_subject/7`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          },
          timeout: 5000 // Timeout after 5 seconds
        });

        if (response.status >= 200 && response.status < 300) { // Checking for 2xx response
          if (response.data && response.data.year_id) {
            setSelectedYear(response.data.year_id);
          } else {
            console.error("Received unexpected data format:", response.data);
          }
        } else {
          console.error("Non-successful response status:", response.status, response.statusText);
        }
      } catch (error) {
        if (error.response) {
          // Received a response but it's not 2xx
          console.error("Error fetching last completed year (HTTP Error):", error.response.status, error.response.statusText);
        } else if (error.request) {
          // Request was made, but no response was received (network error or timeout)
          console.error("Error fetching last completed year (No Response):", error.message);
        } else {
          // Some other error (like setting up the request)
          console.error("Error setting up the request:", error.message);
        }
      }
    }

    fetchLastCompletedYear();
  }, [access_token, student_id]);

  // End of API Call to set the selected year in the dropdown for Business Studies(subjectID7) to the same year as the last lesson completed.

  // IMPORTANT! SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6, Business Studies=7)
  // Function to fetch lessons based on the selected subtopic ID and year
  const fetchLessonsForSubtopic = async (subTopicId) => {
    try {
      const response = await api.get(`/lessons/lessons_by_subject/7/${subTopicId}/${selectedYear}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
  
      if (response.status === 200) {
        // Filter lessons where is_completed is true
        return response.data.filter(lesson => lesson.is_completed === true);
      } else {
        return [];
      }
    } catch (err) {
      console.error("Error fetching lessons:", err);
      return [];
    }
  };

  // End of Function to fetch lessons based on the selected subtopic ID and year

  // Function to fetch completed lessons
  const fetchCompletedLessons = async (subTopicId) => {
    try {
      const response = await api.get(`/progress/student/${student_id}/completed_lessons/7/${subTopicId}/${selectedYear}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        return response.data.map(lesson => lesson.lesson_id);
      } else {
        return [];
      }
    } catch (err) {
      console.error("Error fetching completed lessons:", err);
      return [];
    }
  };

  useEffect(() => {
    const fetchAndSetCompletedLessons = async () => {
      const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));

      let allCompletedLessons = [];

      for (let subTopicId of uniqueSubTopicsForSelectedYear) {
        const lessons = await fetchCompletedLessons(subTopicId);
        allCompletedLessons = [...allCompletedLessons, ...lessons];
      }

      setCompletedLessons(allCompletedLessons);
      console.log('All Completed Lessons:', allCompletedLessons);

    };

    fetchAndSetCompletedLessons();

  }, [selectedYear]); // assuming you want to refetch whenever selectedYear changes

  // End of Function to fetch completed lessons

  // Fetch lessons for each subtopic on page load
  useEffect(() => {
    setLessonsBySubtopic({});

    const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));

    uniqueSubTopicsForSelectedYear.forEach(async (subTopicId) => {
      const lessons = await fetchLessonsForSubtopic(subTopicId);
      const completedLessonIds = await fetchCompletedLessons(subTopicId);

      const updatedLessons = lessons.map(lesson => ({
        ...lesson,
        is_completed: completedLessonIds.includes(lesson.id)
      }));

      setLessonsBySubtopic(prevState => ({ ...prevState, [subTopicId]: updatedLessons }));
    });

  }, [selectedYear]);

  // End of Fetch lessons for each subtopic on page load

  // List of topics for Business Studies, with corresponding years
  const [subTopics, setSubTopics] = useState([
    { id: 215, title: "Introduction to Business: Exploring the Purpose and Types of Businesses", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/215.jpg" },
    { id: 216, title: "Business and Society: How Businesses Impact Our Daily Lives", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/216.jpg" },
    { id: 217, title: "Entrepreneurship Basics: What It Means to Be an Entrepreneur", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/217.jpg" },
    { id: 218, title: "Simple Business Terms and Concepts: Understanding the Basics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/218.jpg" },
    { id: 219, title: "The Role of Technology in Business: An Overview for Beginners", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/219.jpg" },
    { id: 220, title: "Basic Business Operations: Understanding How Businesses Work", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/220.jpg" },
    { id: 221, title: "Introduction to Business Ethics and Sustainability: Making Good Choices", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/221.jpg" },
    { id: 222, title: "Basics of Money Management: Learning to Save and Budget", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/222.jpg" },
    { id: 223, title: "Introduction to Personal Saving Goals: Why Saving Money Matters", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/223.jpg" },
    { id: 224, title: "Understanding Simple Interest: How Saving Money Can Grow Over Time", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/224.jpg" },
    { id: 225, title: "Business Objectives and Aims: Setting Goals in Business", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/225.jpg" },
    { id: 226, title: "Introduction to Marketing: How Businesses Reach Their Customers", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/226.jpg" },
    { id: 227, title: "The World of Finance: Revenue, Costs, and Profit Basics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/227.jpg" },
    { id: 228, title: "Business Operations and Production: How Goods and Services are Made", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/228.jpg" },
    { id: 229, title: "Understanding Business Stakeholders: Who Has an Interest in Businesses?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/229.jpg" },
    { id: 230, title: "The Importance of Business Planning: Creating a Simple Business Plan", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/230.jpg" },
    { id: 231, title: "Exploring Different Types of Business Ownership: Sole Traders to Corporations", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/231.jpg" },
    { id: 232, title: "Introduction to Investing: Simple Concepts of Making Money Work", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/232.jpg" },
    { id: 233, title: "Planning for a Financial Goal: Saving for Something Big", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/233.jpg" },
    { id: 234, title: "Basic Concepts of Borrowing: What Does It Mean to Borrow Money?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/234.jpg" },
    { id: 235, title: "External Influences on Business: Technology, Economy, and Legislation", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/235.jpg" },
    { id: 236, title: "Marketing Mix and Strategies: Dive Deeper into Product, Price, Promotion, and Place", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/236.jpg" },
    { id: 237, title: "Human Resources Management: The Basics of Hiring and Motivating Employees", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/237.jpg" },
    { id: 238, title: "Advanced Business Finance: Introduction to Financial Ratios and Cash-Flow Forecasting", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/238.jpg" },
    { id: 239, title: "The Competitive Environment: Understanding Risk and Reward in Business", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/239.jpg" },
    { id: 240, title: "Business Ethics and Responsibility: A Closer Look at Ethical Business Practices", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/240.jpg" },
    { id: 241, title: "Business Growth Strategies: Exploring How Businesses Expand", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/241.jpg" },
    { id: 242, title: "The Concept of Credit: Understanding Credit Cards and Loans", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/242.jpg" },
    { id: 243, title: "Introduction to Mortgages: How People Buy Homes", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/243.jpg" },
    { id: 244, title: "Investing Basics for Teenagers: Stocks, Bonds, and Savings Accounts", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/244.jpg" },
    { id: 245, title: "Globalisation and International Business: How Businesses Operate Worldwide", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/245.jpg" },
    { id: 246, title: "In-depth Study of Business Operations: From Procurement to Quality Control", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/246.jpg" },
    { id: 247, title: "The Role of Digital Marketing in Modern Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/247.jpg" },
    { id: 248, title: "Analyzing Business Performance: Using Financial Information for Decision Making", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/248.jpg" },
    { id: 249, title: "Understanding Market Research: Qualitative vs Quantitative Approaches", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/249.jpg" },
    { id: 250, title: "Organisational Structures and Communication in Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/250.jpg" },
    { id: 251, title: "Problem Solving in Business: Making Informed Decisions with Quantitative Data", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/251.jpg" },
    { id: 252, title: "Personal Budgeting and Finance Management: Tools and Techniques", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/252.jpg" },
    { id: 253, title: "Understanding Taxes and Payslips: Where Does the Money Go?", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/253.jpg" },
    { id: 254, title: "Principles of Smart Investing: Diversification and Risk Management", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/254.jpg" },
    { id: 255, title: "Preparing for the Business World: Transitioning from GCSE to A-Level or Career", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/255.jpg" },
    { id: 256, title: "Strategic Business Decision Making: Applying Knowledge to Real-World Scenarios", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/256.jpg" },
    { id: 257, title: "E-commerce and Digital Businesses: The Future of Business", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/257.jpg" },
    { id: 258, title: "Sustainability and Global Challenges for Businesses", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/258.jpg" },
    { id: 259, title: "Advanced Marketing Strategies: Segmenting, Targeting, and Positioning", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/259.jpg" },
    { id: 260, title: "Leadership and Management in Business: Developing Effective Business Leaders", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/260.jpg" },
    { id: 261, title: "Evaluating Business Finance: Advanced Calculations and Interpretations for Decision Making", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/261.jpg" },
    { id: 262, title: "Financial Planning for the Future: Pensions and Long-Term Saving Strategies", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/262.jpg" },
    { id: 263, title: "Getting Ready for the Real World: Managing Finances After School", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/263.jpg" },
    { id: 264, title: "Entrepreneurship and Personal Finance: Starting a Business with Minimal Resources", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/264.jpg" },
  ]);
  
  

  const CustomMenuItem = ({ completed, children, ...other }) => {
    return (
      <MenuItem {...other}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{ style: { textDecoration: 'line-through', color: 'green' } }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={event => setSelectedYear(event.target.value)}
      >
        {[7, 8, 9, 10, 11].map((year) => (
          <MenuItem value={year} key={year}>
            Year {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // Rendering of subtopic cards
  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(subTopic => subTopic.year === Number(selectedYear));

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/TimesTableQuiz')}
                  >
                    Go to Quiz
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select defaultValue="" onChange={(event) => navigate(`/Classroom/${event.target.value}`)}>
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>
                      {loading && <MenuItem disabled>Loading...</MenuItem>}
                      {error && !lessonsBySubtopic[subTopic.id]?.length && <MenuItem disabled>Error: {error}</MenuItem>}
                      {lessonsBySubtopic[subTopic.id]?.map(lesson => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem key={lesson.id} value={lesson.id} completed={isCompleted}>
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!access_token) {
    console.warn("Missing access token. Redirecting to login.");
    navigate('/StudentLogin'); // Redirect to login if there's no access token
    return;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => { navigate('/LessonSelect'); }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Business Studies
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="h7" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Business Studies lesson you completed, but feel free to change it whenever!)
        </Typography>
        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}> {/* This will add a margin-top of 3 spacing units */}
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default BusinessStudies;
