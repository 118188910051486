import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../Utils/useApi';


const defaultTheme = createTheme();

const Geography = () => {
  const navigate = useNavigate();





  // Setup a response interceptor - This will redirect to login if we get a 401 response code
axios.interceptors.response.use(
  response => {
    // If the response is successful, just return it
    return response;
  },
  error => {
    // If there's an error in the response, check the status code
    if (error.response && error.response.status === 401) {
      // If it's a 401 (Unauthorized), redirect to login
      navigate('/StudentLogin');
    }
    return Promise.reject(error);
  }
);




  const student_id = localStorage.getItem('user_id');
  const student_year = localStorage.getItem('student_year');
  const access_token = localStorage.getItem('accessToken');
  const [selectedYear, setSelectedYear] = useState(student_year);
  // State to store the lessons for each subtopic
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  // State to track loading state
  const [loading, setLoading] = useState(false);
  // State to track errors
  const [error, setError] = useState(null);
  const [completedLessons, setCompletedLessons] = useState([]);
  const api = useApi();



  const handleLogin = () => {
    navigate('/StudentLogin');
  };




//IMPORTANT!  SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6)
  //API Call to set the selected year in the dropdown for English(subjectID5) to the same year as the last lesson completed.
  useEffect(() => {
    if (!access_token) return;
  
    const fetchLastCompletedYear = async () => {
      try {
        const response = await api.get(`/progress/student/${student_id}/last_completed_year_for_subject/3`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          },
          timeout: 5000 // Timeout after 5 seconds
        });
  
        if (response.status >= 200 && response.status < 300) { // Checking for 2xx response
          if (response.data && response.data.year_id) {
            setSelectedYear(response.data.year_id);
          } else {
            console.error("Received unexpected data format:", response.data);
          }
        } else {
          console.error("Non-successful response status:", response.status, response.statusText);
        }
      } catch (error) {
        if (error.response) {
          // Received a response but it's not 2xx
          console.error("Error fetching last completed year (HTTP Error):", error.response.status, error.response.statusText);
        } else if (error.request) {
          // Request was made, but no response was received (network error or timeout)
          console.error("Error fetching last completed year (No Response):", error.message);
        } else {
          // Some other error (like setting up the request)
          console.error("Error setting up the request:", error.message);
        }
      }
    }
  
    fetchLastCompletedYear();
  }, [access_token, student_id]);


//End of API Call to set the selected year in the dropdown for English(subjectID5) to the same year as the last lesson completed.


//IMPORTANT!  SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6)
// Function to fetch lessons based on the selected subtopic ID and year
const fetchLessonsForSubtopic = async (subTopicId) => {
  try {
    const response = await api.get(`/lessons/lessons_by_subject/3/${subTopicId}/${selectedYear}`, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    });

    if (response.status === 200) {
      // Filter lessons where is_completed is true
      return response.data.filter(lesson => lesson.is_completed === true);
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching lessons:", err);
    return [];
  }
};

//End of Function to fetch lessons based on the selected subtopic ID and year

//Function to fetch completed lessons
const fetchCompletedLessons = async (subTopicId) => {
  try {
    const response = await api.get(`/progress/student/${student_id}/completed_lessons/3/${subTopicId}/${selectedYear}`, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    });
    if (response.status === 200) {
      return response.data.map(lesson => lesson.lesson_id);
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching completed lessons:", err);
    return [];
  }
};

useEffect(() => {
  const fetchAndSetCompletedLessons = async () => {
    const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));

    let allCompletedLessons = [];

    for(let subTopicId of uniqueSubTopicsForSelectedYear){
      const lessons = await fetchCompletedLessons(subTopicId);
      allCompletedLessons = [...allCompletedLessons, ...lessons];
    }

    setCompletedLessons(allCompletedLessons);
    console.log('All Completed Lessons:', allCompletedLessons);

  };

  

  fetchAndSetCompletedLessons();

}, [selectedYear]); // assuming we want to refetch whenever selectedYear changes

//End of Function to fetch completed lessons




// Fetch lessons for each subtopic on page load
useEffect(() => {
  setLessonsBySubtopic({});
  
  const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));
  
  uniqueSubTopicsForSelectedYear.forEach(async (subTopicId) => {
    const lessons = await fetchLessonsForSubtopic(subTopicId);
    const completedLessonIds = await fetchCompletedLessons(subTopicId);

    const updatedLessons = lessons.map(lesson => ({
      ...lesson,
      is_completed: completedLessonIds.includes(lesson.id)
    }));

    setLessonsBySubtopic(prevState => ({ ...prevState, [subTopicId]: updatedLessons }));
  });
  
}, [selectedYear]);


//End of Fetch lessons for each subtopic on page load




  
//List of topics for Geography, with corresponding years

  const [subTopics, setSubTopics] = useState([
    { id: 59, title: "Locational knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 63, title: "Locational knowledge", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 63, title: "Locational knowledge", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    // add more objects for more subtopics, with corresponding years
]);




  const CustomMenuItem = ({ completed, children, ...other }) => {
    return (
      <MenuItem {...other}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{ style: { textDecoration: 'line-through', color: 'green' } }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={event => setSelectedYear(event.target.value)}
      >
        {[...Array(11).keys()].map((_, index) => (
          <MenuItem value={index + 1} key={index}>
            Year {index + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );




  //Rendering of subtopic cards

  const renderSubtopicCards = () => {
    // Only get subTopics for the selected year
    const relevantSubTopics = subTopics.filter(subTopic => subTopic.year === Number(selectedYear));



    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                <FormControl fullWidth>
                  <Select defaultValue="" onChange={(event) => navigate(`/Classroom/${event.target.value}`)}>
                    <MenuItem value="" disabled>
                      Select a lesson
                    </MenuItem>
                    {loading && <MenuItem disabled>Loading...</MenuItem>}
                    {error && !lessonsBySubtopic[subTopic.id]?.length && <MenuItem disabled>Error: {error}</MenuItem>}
                    {lessonsBySubtopic[subTopic.id]?.map(lesson => {
  const isCompleted = completedLessons.includes(lesson.id);
  return (
    <CustomMenuItem key={lesson.id} value={lesson.id} completed={isCompleted}>
      {lesson.title}
    </CustomMenuItem>
  );
})}

                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
    
    
  }
  
  

if (!access_token) {
  console.warn("Missing access token. Redirecting to login.");
  navigate('/StudentLogin'); // Redirect to login if there's no access token
  return;
}


  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => { console.log("Clicked!"); navigate('/LessonSelect'); }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
          Geography
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
    Select a year first.
</Typography>
<Typography variant="h5" align="center" color="text.secondary" paragraph>
    Don't be scared to drop down or move up years, there's value in practicing the basics, or going for a challenge!
</Typography>
<Typography variant="h5" align="center" color="text.secondary" paragraph>
    <b>Year Selector</b>
</Typography>

        <Typography variant="h7" align="center" color="text.secondary" paragraph>
(Note: This will default to the year of the last Geography lesson you completed, but feel free to change it whenever!)
        </Typography>
        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}> {/* This will add a margin-top of 3 spacing units */}
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
  
  
};

export default Geography;
