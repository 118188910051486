import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import useApi from '../Utils/useApi';

const AvatarIconLBFast = ({ userIds, avatars: preloadedAvatars = {} }) => {
    const [avatars, setAvatars] = useState(preloadedAvatars);
    const [loading, setLoading] = useState(Object.keys(preloadedAvatars).length === 0);
    const [error, setError] = useState('');
  
    const api = useApi();
  
    useEffect(() => {
      if (loading && userIds && userIds.length > 0) {
        fetchBatchAvatars();
      }
    }, [userIds, loading]);
  
    const fetchBatchAvatars = async () => {
      try {
        const response = await api.post('/avatar/users', { user_ids: userIds });
        if (response.data) {
          const avatarMap = response.data.reduce((acc, avatarData) => {
            acc[avatarData.user_id] = avatarData.components.sort((a, b) => a.layer - b.layer);
            return acc;
          }, {});
          setAvatars(avatarMap);
        } else {
          setError('Failed to fetch avatars');
        }
      } catch (error) {
        setError('API Error while fetching avatars');
      } finally {
        setLoading(false);
      }
    };
  
    const renderAvatar = (userId) => {
      const components = avatars[userId];
      if (!components || components.length === 0) {
        return (
          <Avatar
            sx={{
              width: 24,
              height: 24,
              backgroundColor: '#e0e0e0',
            }}
            alt="No Avatar"
          />
        );
      }
  
      return (
        <Avatar
          sx={{
            width: 24,
            height: 24,
            position: 'relative',
            overflow: 'hidden',
          }}
          alt="User Avatar"
        >
          {components.map((comp) => (
            <img
            key={comp.id}
            src={comp.image_url}
            alt={comp.type}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          
          ))}
        </Avatar>
      );
    };
  
    if (loading) return <CircularProgress size={24} />;
    if (error) {
      return <span>{error}</span>;
    }
  
    return (
      <>
        {userIds.map((userId) => (
          <React.Fragment key={userId}>
            {renderAvatar(userId)}
          </React.Fragment>
        ))}
      </>
    );
  };
  
  export default AvatarIconLBFast;
  