import * as React from 'react'; 
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import IntroVideos from './IntroVideos';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Typography, Container } from '@mui/material';
import UserGreeting from './UserGreeting';
import PricingInfo from './PricingInfo';
import { Helmet } from 'react-helmet-async';

const defaultTheme = createTheme({
  typography: {
    htmlFontSize: 20,
    h2: {
      fontSize: 'clamp(1.2rem, 5vw, 1.5rem)',
    },
    h5: {
      fontSize: 'clamp(0.8rem, 3vw, 1.2rem)',
    },
    h6: {
      fontSize: 'clamp(0.9rem, 2.5vw, 1rem)',
    },
  },
});

export default function AboutUs() {
  const navigate = useNavigate();
  const [userType, setUserType] = useState(localStorage.getItem('usertype'));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleContactClick = () => {
    navigate('/contactus');
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setUserType(localStorage.getItem('usertype'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const getButtonText = () => {
    if (userType === 'parent' || userType === 'student') {
      return 'Go to your Dashboard';
    }
    return 'Sign up now for free';
  };

  const handleButtonClick = () => {
    if (userType === 'parent') {
      navigate('/ParentDashboard');
    } else if (userType === 'student') {
      navigate('/StudentDashboard');
    } else {
      navigate('/Register');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
       <Helmet>
        <title>About Us | Rocket Learning</title>
        <meta name="description" content="Discover how Rocket Learning transforms home education with UK curriculum-aligned lessons, an AI tutor, and comprehensive learning support across subjects like Maths, English, Science, Geography, History, ICT and Business Studies." />
        <meta name="keywords" content="home education, homeschooling, UK curriculum, AI tutor, learning support, educational platform, Rocket Learning" />
        <meta property="og:title" content="About Us | Rocket Learning" />
        <meta property="og:description" content="Rocket Learning provides a future-focused home education experience with engaging lessons aligned to the UK National Curriculum. Explore subjects such as Maths, Science, and Business Studies." />
        <meta property="og:image" content="https://websitecontent.ams3.cdn.digitaloceanspaces.com/BusinessBanner.png" />
        <meta property="og:url" content="https://rocketlearning.uk/aboutus" />
      </Helmet>

      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <nav>
            {/* Your navigation items here */}
          </nav>
          <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Contact Us
          </Button>
          <Box flexGrow={1}></Box>
          <UserGreeting />
        </Toolbar>
      </AppBar>

      <Button onClick={() => navigate('/rocket-arcade')} sx={{ p: 0, width: '100%' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'block',
          }}
          src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/BusinessBanner.png"
          alt="Rocket Learning Banner"
        />
      </Button>

      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6,  }}>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Transform Your Child's Learning Experience with Rocket Learning
        </Typography>

        <Typography variant="h4" align="center" color="text.secondary" component="p" sx={{ mt: 4 }}>
          Why Choose Rocket Learning?
        </Typography>
        <br />
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          <strong>UK Curriculum-Aligned Lessons:</strong> Our platform is built to align with the UK National Curriculum, ensuring your child learns exactly what they need, from primary school through GCSEs.
        </Typography>
        <br />
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          <strong>Comprehensive Learning Across Subjects:</strong> Explore our complete range of subjects: Maths, English, Science, Geography, History, ICT, and Business Studies—all designed to build essential skills for success.
        </Typography>
        <br />
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Dive into an engaging home education experience with Rocket Learning. Choose a brighter, future-focused education for your child today.
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 2 }}>
          <Typography variant="h4" align="center" color="text.secondary" component="p">
            Try Rocket Learning free for 14 days. <b>No payment details required.</b> Discover how we make learning fit your child, not the other way around.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 2 }}>
          <Button size="large" variant="contained" color="primary" onClick={handleButtonClick}>
            {getButtonText()}
          </Button>
          <Button size="large" variant="outlined" onClick={() => navigate('/ParentLogin')}>
            Parent Login
          </Button>
          <Button size="large" variant="outlined" onClick={() => navigate('/StudentLogin')}>
            Student Login
          </Button>
        </Box>
      </Container>

      <PricingInfo />

      <IntroVideos 
        key="leftSection"
        videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/MainAdWindow1-1.mp4"
        text={(
          <React.Fragment>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Unlock Future-Focused Learning for Your Child
            </Typography>
            <Typography variant="h5" align="left" color="textPrimary" sx={{ fontSize: 'clamp(1rem, 3vw, 1.2rem)' }}>
              Traditional education methods aren't enough for today's fast-paced world. Rocket Learning bridges the gap with structured, engaging lessons that spark your child's curiosity and drive results.
            </Typography>
          </React.Fragment>
        )}
        direction="left"
      />

      <IntroVideos 
        key="rightSection"
        videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/LessonVid1.mp4"
        text={(
          <React.Fragment>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Our Lessons
            </Typography>
            <Typography variant="h5" align="left" color="textPrimary" sx={{ fontSize: 'clamp(1rem, 3vw, 1.2rem)' }}>
              Each lesson follows the UK National Curriculum, preparing children for key assessments, including GCSEs and iGCSEs. We support Year 1 to Year 11 in subjects such as Maths, English, Science, and more.
            </Typography>
            <List sx={{ paddingLeft: 4 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">Maths</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">English</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">Science (Biology, Chemistry & Physics)</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">Geography</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">History</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">ICT</Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="h5" align="center" color="textPrimary">Business Studies (from year 7)</Typography>
              </ListItem>
            </List>
          </React.Fragment>
        )}
        direction="right"
      />

      <IntroVideos 
        key="leftSection"
        videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/AIVid1.MP4"
        text={(
          <React.Fragment>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              AI Tutor
            </Typography>
            <Typography variant="h5" align="left" color="textPrimary" sx={{ fontSize: 'clamp(1rem, 3vw, 1.2rem)' }}>
              Rocket Learning's AI Tutor supports your child's learning journey by providing explanations, assistance on tough questions, and extra help when needed. Designed to complement parental involvement, our AI tutor ensures learning continues, even during busy times.
            </Typography>
          </React.Fragment>
        )}
        direction="left"
      />

      <IntroVideos 
        key="rightSection"
        videoSrc="https://websitecontent.ams3.cdn.digitaloceanspaces.com/Slideshow.mp4"
        text={(
          <React.Fragment>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Rocket Learning: Your Partner in Educational Excellence
            </Typography>
            <Typography variant="h5" align="left" color="textPrimary" sx={{ fontSize: 'clamp(1rem, 3vw, 1.2rem)' }}>
              Rocket Learning goes beyond traditional education by providing a platform that is comprehensive, accessible, and geared towards helping your child thrive. We strive to be the UK's most supportive home learning solution, ready to help students excel and prepare for the future.
            </Typography>
          </React.Fragment>
        )}
        direction="right"
      />

      <Container maxWidth="md" component="main">
        {/* Additional content sections if needed */}
      </Container>
    </ThemeProvider>
  );
}
