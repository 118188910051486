import { useEffect } from 'react';
import useApi from '../Utils/useApi'; // Assuming you have a custom API hook

const RocketFuelManager = () => {
    const api = useApi();

    useEffect(() => {
        const handleScoreMessage = (event) => {
            const { score, gameName } = event.data;

            if (!score || !gameName) return;

            console.log(`Received score ${score} from game ${gameName}`);

            const userId = localStorage.getItem('user_id');
            const accessToken = localStorage.getItem('accessToken');

            // Update RocketFuel logic
            const reward = Math.floor(score / 10); // 1 RocketFuel per 10 points
            api.put(`/user/update_rocketfuel/${userId}`, { amount: reward }, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then(() => {
                console.log('RocketFuel updated successfully!');
                // Now update the weekly RocketFuel
                return api.post('/rfleague/add-rocketfuel', { user_id: userId, rocketfuel: reward }, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
            })
            .then(() => {
                console.log('Weekly RocketFuel updated successfully!');

                // Check if the game has a leaderboard
                if (['Mathsteroids', 'SpellBound'].includes(gameName)) {
                    // Submit score to leaderboard
                    return api.post(`/games/${gameName.toLowerCase()}/submit_score`, { userId, score }, {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    })
                    .then((response) => {
                        if (response.status === 201) {
                            console.log(`${gameName} leaderboard updated successfully!`);
                        } else {
                            console.error('Failed to update leaderboard');
                        }
                    });
                } else {
                    console.log(`${gameName} does not have a leaderboard. Skipping leaderboard update.`);
                }
            })
            .catch((error) => {
                console.error('Error updating RocketFuel or leaderboard:', error);
            });
        };

        window.addEventListener('message', handleScoreMessage);

        return () => {
            window.removeEventListener('message', handleScoreMessage);
        };
    }, [api]);

    return null; // This component does not render anything
};

export default RocketFuelManager;
