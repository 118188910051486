// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Existing styles for footer container, links, and typography */
.Footer_footer-container__Ocrdn {
  background-color: #4a90e2;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Footer_footer-links__0pj9X {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.Footer_left-links__Dyj-N, .Footer_right-link__FkQsR {
  display: flex;
  align-items: center;
}

.Footer_left-links__Dyj-N a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
}

.Footer_right-link__FkQsR {
  color: #fff;
  font-size: 16px; 
}

/* Updated responsive behavior */
@media (max-width: 768px) {
    .Footer_footer-links__0pj9X {
        display: none; /* Hide all footer links on small screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAAA,gEAAgE;AAChE;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA,gCAAgC;AAChC;IACI;QACI,aAAa,EAAE,2CAA2C;IAC9D;AACJ","sourcesContent":["/* Existing styles for footer container, links, and typography */\n.footer-container {\n  background-color: #4a90e2;\n  height: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 20px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}\n\n.footer-links {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  max-width: 800px;\n}\n\n.left-links, .right-link {\n  display: flex;\n  align-items: center;\n}\n\n.left-links a {\n  color: #fff;\n  text-decoration: none;\n  font-size: 16px;\n  margin-right: 20px;\n}\n\n.right-link {\n  color: #fff;\n  font-size: 16px; \n}\n\n/* Updated responsive behavior */\n@media (max-width: 768px) {\n    .footer-links {\n        display: none; /* Hide all footer links on small screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-container": `Footer_footer-container__Ocrdn`,
	"footer-links": `Footer_footer-links__0pj9X`,
	"left-links": `Footer_left-links__Dyj-N`,
	"right-link": `Footer_right-link__FkQsR`
};
export default ___CSS_LOADER_EXPORT___;
