import React, { useState, useContext, useEffect, useRef } from 'react';
import useApi from '../Utils/useApi';
import styles from './InteractiveWindow.module.css';
import { ChatContext } from './ChatContext';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import he from 'he'; 

const InteractiveWindow = ({ lessonId, userName, lessonData, helpQuestion, handleCloseWindow }) => {
    const { chatLog, addMessageToChatLog, clearChatLog } = useContext(ChatContext);
    const [name, setName] = useState('');
    const [age, setAge] = useState(null); // New state for age
    const [isLoading, setIsLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [initialPromptSent, setInitialPromptSent] = useState(false); // New state for initial prompt
    const chatLogEndRef = useRef(null);
    const api = useApi();

    const scrollToBottom = () => {
        chatLogEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, [chatLog]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleUserMessage();
        }
    };

    useEffect(() => {
        clearChatLog();
    }, []);

    useEffect(() => {
        const fetchUserName = async () => {
            const userId = localStorage.getItem('user_id');
            const token = localStorage.getItem('accessToken');
            try {
                const userResponse = await api.get(`/user/getname/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const { firstname, lastname, age } = userResponse.data;
                setName(`${firstname} ${lastname}`);
                setAge(age);  // Store the age
            } catch (error) {
                console.error('Error fetching user name:', error);
                setErrorMessage("You must be logged in to access the Classroom.");
            }
        };
    
        fetchUserName();
    }, []);

    useEffect(() => {
        const sendInitialPrompt = async () => {
            if (name && lessonData) {
                let initialPrompt = `You are an AI tutor helping a student named ${userName}.`;

                if (age) {
                    initialPrompt += ` The student is ${age} years old.`;
                }

                // Tailor the tone based on age
                if (age) {
                    if (age <= 7) {
                        initialPrompt += ` Use simple words and short sentences suitable for a ${age}-year-old child. Speak directly to ${userName} in a friendly and encouraging tone. Engage with them using positive reinforcement.`;
                    } else if (age <= 12) {
                        initialPrompt += ` Use clear and straightforward explanations suitable for a ${age}-year-old. Engage ${userName} with examples relatable to their age group, and maintain a friendly, conversational tone. Encourage questions and be supportive.`;
                    } else {
                        initialPrompt += ` Provide detailed explanations appropriate for a ${age}-year-old student. Use academic language suitable for middle to high school students, and speak directly to ${userName} in a respectful and engaging manner. Encourage deeper understanding and critical thinking.`;
                    }
                } else {
                    initialPrompt += ` Since the student's age is not provided, please use clear and age-neutral language suitable for a wide range of students. Speak directly to the student, using their name, and maintain a friendly and conversational tone.`;
                }

                if (helpQuestion) {
                    const questionText = helpQuestion.question_text;
                    const helpPrompt = helpQuestion.help_prompt;
                    const correctAnswer = helpQuestion.answer;

                    initialPrompt += ` The student is trying to answer this question: "${questionText}". The correct answer is "${correctAnswer}". ${helpPrompt}`;
                }

                // Instruction for formatting mathematical expressions
                initialPrompt += ` Please format any mathematical expressions using LaTeX, enclosed within $$ for display math or $ for inline math.`;

                try {
                    setIsLoading(true);
                    const res = await api.post('/engine/simple_chat', {
                        messages: [{ "role": "system", "content": initialPrompt }],
                    });
                    console.log('Initial prompt response:', res.data.result);
                    addMessageToChatLog({ user: 'assistant', message: res.data.result }); // Changed 'system' to 'assistant'
                } catch (error) {
                    console.error('Error sending initial prompt:', error);
                    setErrorMessage('There was a problem communicating with the AI tutor. Please try again later.');
                } finally {
                    setIsLoading(false);
                }
            }
        };

        // Ensure the initial prompt is sent only once
        if (name && lessonData && !initialPromptSent) {
            sendInitialPrompt();
            setInitialPromptSent(true);
        }
    }, [name, lessonData, userName, helpQuestion, age, initialPromptSent]);

    const handleUserMessage = async () => {
        if (userInput.trim() === '') return;
    
        addMessageToChatLog({ user: 'user', message: userInput }); // Ensure user role is 'user'
    
        const messages = [
            ...chatLog.map(chat => {
                return { "role": chat.user.toLowerCase(), "content": chat.message }
            }),
            { "role": "user", "content": userInput }
        ];
        

        try {
            setIsLoading(true);
            const res = await api.post('/engine/simple_chat', {
                messages,
            });
            console.log('User message response:', res.data.result);
            addMessageToChatLog({ user: 'assistant', message: res.data.result }); // Changed 'system' to 'assistant'
            setUserInput('');  // clear input
        } catch (error) {
            console.error('Error sending user message:', error);
            setErrorMessage('There was a problem communicating with the AI tutor. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const sanitizeLaTeX = (message) => {
        // Decode HTML entities
        message = he.decode(message);
        return message;
    };

    const formatMessage = (message) => {
        console.log("Original message:", message);
    
        message = sanitizeLaTeX(message);
    
        console.log("Sanitized message:", message);
    
        const latexRegex = /\$\$([\s\S]*?)\$\$|\$([\s\S]*?)\$/g; // Simplified regex
        const parts = [];
        let lastIndex = 0;
        let match;
    
        while ((match = latexRegex.exec(message)) !== null) {
            // Text before LaTeX
            if (match.index > lastIndex) {
                const textPart = message.substring(lastIndex, match.index);
                parts.push({ type: 'text', content: textPart });
            }
            // LaTeX content
            const latexContent = match[0];
            parts.push({ type: 'math', content: latexContent });
            lastIndex = latexRegex.lastIndex;
        }
    
        // Remaining text after last LaTeX
        if (lastIndex < message.length) {
            const textPart = message.substring(lastIndex);
            parts.push({ type: 'text', content: textPart });
        }
    
        return parts.map((part, idx) => {
            if (part.type === 'math') {
                return (
                    <MathJax key={idx} dynamic>
                        {part.content}
                    </MathJax>
                );
            } else {
                // Handle newlines
                return part.content.split('\n').map((line, lineIdx) => (
                    <p key={`${idx}-${lineIdx}`}>{line}</p>
                ));
            }
        });
    };
    

    return (
        <MathJaxContext
            version={3}
            config={{
                tex: {
                    inlineMath: [
                        ['$', '$'],
                    ],
                    displayMath: [
                        ['$$', '$$'],
                    ],
                    processEscapes: true,
                },
            }}
        >
            <div className={styles.interactiveWindow}>
                <button onClick={handleCloseWindow} className={styles.closeButton}>Close</button>
                <div className={styles.interactiveWindowContent}>
                    {chatLog.map((message, idx) => (
                        <div key={idx} className={styles.messageContainer}>
                            {message.user === 'assistant' ? ( // Changed 'system' to 'assistant'
                                <div className={styles.assistantMessage}>
                                    {formatMessage(message.message)}
                                </div>
                            ) : (
                                <div className={styles.userMessage}>
                                    <strong>{message.user}:</strong> {message.message}
                                </div>
                            )}
                        </div>
                    ))}
                    {isLoading && (
                        <div className={styles.loadingContainer}>
                            <CircularProgress />
                            <p>AI Tutor is thinking...</p>
                        </div>
                    )}
                    {errorMessage && ( // Display error messages
                        <div className={styles.errorMessage}>
                            {errorMessage}
                        </div>
                    )}
                    <div ref={chatLogEndRef} />  {/* This is our marker div */}
                </div>

                <div className={styles.inputForm}>
                    <input
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Ask your question..."
                        className={styles.inputFormInput}
                    />
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleUserMessage} 
                        className={styles.inputFormButton}
                        disabled={isLoading} // Disable button when loading
                    >
                        Send
                    </Button>
                </div>
            </div>
        </MathJaxContext>
    );
};

export default InteractiveWindow;
