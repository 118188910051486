// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundImage {
    background-image: url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/Checkoutimage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh; /* Set a fixed height */
  }
  `, "",{"version":3,"sources":["webpack://./src/TrustPayments/Checkout.css"],"names":[],"mappings":"AAAA;IACI,+FAA+F;IAC/F,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa,EAAE,uBAAuB;EACxC","sourcesContent":[".backgroundImage {\n    background-image: url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/Checkoutimage.png);\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 100vh; /* Set a fixed height */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
