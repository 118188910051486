import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Box,
} from '@mui/material';
import axios from 'axios';

const IncompleteLessonsMenu = ({ onLessonSelect }) => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncompleteLessons = async () => {
      try {
        const access_token = localStorage.getItem('accessToken');
        const response = await axios.get('https://orca-app-mna7u.ondigitalocean.app/admin/lessons/incomplete', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });

        setLessons(response.data.lessons);
      } catch (err) {
        setError('Failed to fetch lessons. Please try again later.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchIncompleteLessons();
  }, []);

  const handleLessonClick = (lessonId) => {
    if (onLessonSelect) {
      onLessonSelect(lessonId); // Trigger callback if provided
    } else {
      navigate(`/admin/lessons/${lessonId}`);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" marginTop={4}>
        {error}
      </Typography>
    );
  }

  return (
    <Box marginY={4}>
      <Typography variant="h6" gutterBottom>
        Incomplete Lessons
      </Typography>
      <List>
        {lessons.length > 0 ? (
          lessons.map((lesson) => (
            <ListItem
              button
              key={lesson.id}
              onClick={() => handleLessonClick(lesson.id)}
            >
              <ListItemText
                primary={`ID: ${lesson.id} | Subject: ${lesson.subject_name || 'N/A'} | Year: ${lesson.year_id} | Title: ${lesson.title}`}
                secondary={`Topic: ${lesson.topic_name || 'N/A'}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1" align="center">
            No incomplete lessons found.
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default IncompleteLessonsMenu;
