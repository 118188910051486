import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery('(max-width: 900px)');
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    setLoggedIn(!!userId);
  }, [loggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('accessToken');
    setLoggedIn(false);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [loginAnchorEl, setLoginAnchorEl] = useState(null);
  const loginOpen = Boolean(loginAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#4a90e2" }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: isMediumScreen ? '40px' : '60px',
                width: 'auto',
                maxWidth: '100%',
                transition: 'height 0.3s ease-in-out',
              }}
            />
          </Link>
        </Box>

        {isSmallScreen ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="mobile-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'mobile-menu',
              }}
            >
              <MenuItem component={Link} to="/" onClick={handleMenuClose}>Home</MenuItem>
              <MenuItem component={Link} to="/Register" onClick={handleMenuClose}>Create Account</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentDashboard">Parent Dashboard</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentDashboard">Student Dashboard</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentLogin">Parent Login</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentLogin">Student Login</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/LessonSelect">To the Classroom!</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/rocket-arcade">Rocket Arcade</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/Assessments">Assessments</MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button color="inherit" component={Link} to="/" sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}>Home</Button>
            <Button color="inherit" component={Link} to="/RLBlog" sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}>Parent-Zone</Button>
            <Button color="inherit" component={Link} to="/Register" sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}>Create Account</Button>
            <Button color="inherit" component={Link} to="/Assessments" sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}>Student Assessments</Button>
            <Button
              id="dashboard-menu"
              aria-controls="dashboard-menu"
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={handleMenuClick}
              color="inherit"
              sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}
            >
              Dashboards
            </Button>
            <Menu
              id="dashboard-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'dashboard-menu',
              }}
            >
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentDashboard">Parent Dashboard</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentDashboard">Student Dashboard</MenuItem>
            </Menu>
            <Button
              id="login-menu"
              aria-controls="login-menu"
              aria-haspopup="true"
              aria-expanded={loginOpen ? 'true' : undefined}
              onClick={handleLoginClick}
              color="inherit"
              sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}
            >
              Login
            </Button>
            <Menu
              id="login-menu"
              anchorEl={loginAnchorEl}
              open={loginOpen}
              onClose={handleLoginClose}
              MenuListProps={{
                'aria-labelledby': 'login-menu',
              }}
            >
              <MenuItem onClick={handleLoginClose} component={Link} to="/ParentLogin">Parent Login</MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/StudentLogin">Student Login</MenuItem>
            </Menu>
            <Button variant="contained" color="secondary" component={Link} to="/LessonSelect" sx={{ fontSize: isMediumScreen ? '0.75rem' : '1rem' }}>To the Classroom!</Button>
            <Button
              onClick={() => navigate('/rocket-arcade')}
              sx={{ ml: 2, fontSize: isMediumScreen ? '0.75rem' : '1rem' }}
            >
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 'auto',
                }}
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RArcadeButton.png"
                alt="Rocket Arcade Button"
              />
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
