import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../Utils/useApi';

const defaultTheme = createTheme();

const History = () => {
  const navigate = useNavigate();





  // Setup a response interceptor - This will redirect to login if we get a 401 response code
axios.interceptors.response.use(
  response => {
    // If the response is successful, just return it
    return response;
  },
  error => {
    // If there's an error in the response, check the status code
    if (error.response && error.response.status === 401) {
      // If it's a 401 (Unauthorized), redirect to login
      navigate('/StudentLogin');
    }
    return Promise.reject(error);
  }
);




  const student_id = localStorage.getItem('user_id');
  const student_year = localStorage.getItem('student_year');
  const access_token = localStorage.getItem('accessToken');
  const [selectedYear, setSelectedYear] = useState(student_year);
  // State to store the lessons for each subtopic
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  // State to track loading state
  const [loading, setLoading] = useState(false);
  // State to track errors
  const [error, setError] = useState(null);
  const [completedLessons, setCompletedLessons] = useState([]);
  const api = useApi();



  const handleLogin = () => {
    navigate('/StudentLogin');
  };




//IMPORTANT!  SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6)
  //API Call to set the selected year in the dropdown for English(subjectID5) to the same year as the last lesson completed.
  useEffect(() => {
    if (!access_token) return;
  
    const fetchLastCompletedYear = async () => {
      try {
        const response = await api.get(`/progress/student/${student_id}/last_completed_year_for_subject/4`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          },
          timeout: 5000 // Timeout after 5 seconds
        });
  
        if (response.status >= 200 && response.status < 300) { // Checking for 2xx response
          if (response.data && response.data.year_id) {
            setSelectedYear(response.data.year_id);
          } else {
            console.error("Received unexpected data format:", response.data);
          }
        } else {
          console.error("Non-successful response status:", response.status, response.statusText);
        }
      } catch (error) {
        if (error.response) {
          // Received a response but it's not 2xx
          console.error("Error fetching last completed year (HTTP Error):", error.response.status, error.response.statusText);
        } else if (error.request) {
          // Request was made, but no response was received (network error or timeout)
          console.error("Error fetching last completed year (No Response):", error.message);
        } else {
          // Some other error (like setting up the request)
          console.error("Error setting up the request:", error.message);
        }
      }
    }
  
    fetchLastCompletedYear();
  }, [access_token, student_id]);


//End of API Call to set the selected year in the dropdown for English(subjectID5) to the same year as the last lesson completed.


//IMPORTANT!  SET THE SUBJECT ID IN THE API CALL HERE TODO: MAKE THIS DYNAMIC (Ref: Maths=1, Science=2, Geography=3, History=4, English=5, ICT=6)
// Function to fetch lessons based on the selected subtopic ID and year
const fetchLessonsForSubtopic = async (subTopicId) => {
  try {
    const response = await api.get(`/lessons/lessons_by_subject/4/${subTopicId}/${selectedYear}`, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    });

    if (response.status === 200) {
      // Filter lessons where is_completed is true
      return response.data.filter(lesson => lesson.is_completed === true);
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching lessons:", err);
    return [];
  }
};

//End of Function to fetch lessons based on the selected subtopic ID and year

//Function to fetch completed lessons
const fetchCompletedLessons = async (subTopicId) => {
  try {
    const response = await api.get(`/progress/student/${student_id}/completed_lessons/4/${subTopicId}/${selectedYear}`, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    });
    if (response.status === 200) {
      return response.data.map(lesson => lesson.lesson_id);
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching completed lessons:", err);
    return [];
  }
};

useEffect(() => {
  const fetchAndSetCompletedLessons = async () => {
    const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));

    let allCompletedLessons = [];

    for(let subTopicId of uniqueSubTopicsForSelectedYear){
      const lessons = await fetchCompletedLessons(subTopicId);
      allCompletedLessons = [...allCompletedLessons, ...lessons];
    }

    setCompletedLessons(allCompletedLessons);
    console.log('All Completed Lessons:', allCompletedLessons);

  };

  

  fetchAndSetCompletedLessons();

}, [selectedYear]); // assuming you want to refetch whenever selectedYear changes

//End of Function to fetch completed lessons




// Fetch lessons for each subtopic on page load
useEffect(() => {
  setLessonsBySubtopic({});
  
  const uniqueSubTopicsForSelectedYear = Array.from(new Set(subTopics.filter(st => st.year === Number(selectedYear)).map(st => st.id)));
  
  uniqueSubTopicsForSelectedYear.forEach(async (subTopicId) => {
    const lessons = await fetchLessonsForSubtopic(subTopicId);
    const completedLessonIds = await fetchCompletedLessons(subTopicId);

    const updatedLessons = lessons.map(lesson => ({
      ...lesson,
      is_completed: completedLessonIds.includes(lesson.id)
    }));

    setLessonsBySubtopic(prevState => ({ ...prevState, [subTopicId]: updatedLessons }));
  });
  
}, [selectedYear]);


//End of Fetch lessons for each subtopic on page load




  
//List of topics for History, with corresponding years

  const [subTopics, setSubTopics] = useState([
    { id: 75, title: "A Journey Through Time", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-timetravel.avif" },
    { id: 76, title: "Daily Life - Then and Now", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-dailylife.avif" },
    { id: 77, title: "Introduction to Historical Figures", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-figures.avif" },
    { id: 78, title: "Festivals Through Time", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-festivals.avif" },
    { id: 79, title: "The Tale of the First Aeroplane Flight", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-firstflight.avif" },
    { id: 80, title: "The Great Fire of London", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-greatfire.avif" },
    { id: 81, title: "From Quills to Keyboards", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-quilltokeyboard.avif" },
    { id: 82, title: "Spotlight on Exploration", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-exploration.avif" },
    { id: 83, title: "Empowering Women in History", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-empoweringwomen.avif" },
    { id: 84, title: "Art Through the Ages", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-art.avif" },
    { id: 85, title: "Nurses who Made a Difference", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-nurses.avif" },
    { id: 213, title: "The Egyptians", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-theegyptians.avif" },

    { id: 86, title: "The World of Stone Age Britons", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-stoneage.avif" },
    { id: 87, title: "Travelling through the Bronze Age", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-bronzeage.avif" },
    { id: 88, title: "The Mighty Iron Age", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ironage.avif" },
    { id: 89, title: "Step into Roman Britain", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-romanempire.avif" },
    { id: 90, title: "Local Legends", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-locallegends.avif" },
    { id: 214, title: "William the Conqueror", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-williamtheconq.avif" },

    
    { id: 91, title: "Rome's Influence on Britain", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-romanempire.avif" },
    { id: 92, title: "Life as an Anglo-Saxon", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-anglosaxon.avif" },
    { id: 93, title: "Invasions and Battles", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-battles.avif" },
    { id: 94, title: "Local History Mysteries", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-locallegends.avif" },
    { id: 95, title: "Beyond 1066", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-beyond1066.avif" },
    { id: 131, title: "Titanic: The Unsinkable Ship and Its Fateful Journey", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-titanic.avif" },
    
    { id: 96, title: "Monarchs and Their Power", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-monarchy.avif" },
    { id: 97, title: "From Crime to Leisure", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-crime.avif" },
    { id: 98, title: "Gifts from Greece & Rome", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-rome.avif" },
    { id: 99, title: "Changing Times in British History", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 100, title: "Civilizations of the Past", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ancient.avif" },
    { id: 132, title: "World War I: Origins, Battles, and Aftermath", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1.avif" },
    
    { id: 101, title: "Glimpses of Ancient Greece", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-rome.avif" },
    { id: 102, title: "World Beyond Britain", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-theegyptians.avif" },
    { id: 103, title: "Mysteries of the Mayan Civilization", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-mayans.avif" },
    { id: 104, title: "Benin's Rich Heritage", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-benin.avif" },
    { id: 105, title: "Local History Chronicles", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 133, title: "World War II: Rise of Dictators and Global Conflict", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww2.avif" },
  
    { id: 113, title: "The Norman Impact", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-normanconquest.avif" },
    { id: 114, title: "Religious Britain", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-religeousbritain.avif" },
    { id: 115, title: "Royal Power Plays", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-royalpower.avif" },
    { id: 116, title: "Medieval Society and Economy", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-medieval.avif" },
    { id: 117, title: "Crises and Responses", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-crisis.avif" },
    { id: 118, title: "Wars and Territories", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-wars.avif" },
    { id: 134, title: "World War I Deep Dive: Trench Warfare, Technologies, and Treaties", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1trench.avif" },
    
    { id: 119, title: "Renaissance and Reformations", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-renaissance.avif" },
    { id: 120, title: "Elizabethan England", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-elizabethan.avif" },
    { id: 121, title: "Civil Wars and Interregnum", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-civilwar.avif" },
    { id: 122, title: "Britain’s Expansion", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 123, title: "Changing Power and Governance", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-power.avif" },
    { id: 124, title: "Daily Life in Early Modern Britain", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-modernbritain.avif" },
    { id: 135, title: "World War II In-depth: Strategy, Home Front, and Global Impact", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww22.avif" },
    
    { id: 125, title: "Enlightened Thinking", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EnlightenedThinking.avif" },
    { id: 126, title: "Colonialism and Slavery", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ColonialismandSlavery.avif" },
    { id: 127, title: "Wars for Dominance", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-WarsforDominance.avif" },
    { id: 128, title: "Industrial and Social Revolution", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-Industrial%20and%20Social%20Revolution.avif" },
    { id: 129, title: "Empire's Reach", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EmpiresReach.avif" },
    { id: 130, title: "Victorian Britain", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-VictorianBritain.avif" },
   
    { id: 109, title: "Renaissance, Reformation, and the Elizabethan Era (1509-1745)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-renaissance.avif" },   
    { id: 136, title: "The Tudors: Power, Politics, and Reformation", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-tudors.avif" },
    { id: 137, title: "Industrial Britain: Technological Revolution and Social Change (1750-1900)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-Industrial%20and%20Social%20Revolution.avif" },
    { id: 138, title: "The French Revolution: Causes, Courses, and Consequences", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-frenchrevolution.avif" },
    { id: 139, title: "A Thematic Study: Religion and Society in Britain (from Roman Britain to the present day)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-religeousbritain.avif" },
    { id: 140, title: "Historic Environment: Exploring Londons East End during the Victorian Era", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    
    { id: 141, title: "The Cold War Era: Tensions, Confrontations, and Resolutions", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-coldwar.avif" },
    { id: 142, title: "Decolonisation and the End of Empires: 20th Century World", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EmpiresReach.avif" },
    { id: 143, title: "Britains Role in the Slave Trade and Abolition Movements", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-slave.avif" },
    { id: 144, title: "A Thematic Study: Medical Advances Through the Ages", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-medicaladvances.avif" },
    { id: 145, title: "Historic Environment: The Battlefields of World War I", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1.avif" },
    { id: 110, title: "Revolution and Reform: Britain (1745-1901)", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-royalpower.avif" },
    { id: 111, title: "World War, Welfare, and Britain's Modern Identity (1901-Present)", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1trench.avif" },
    { id: 112, title: "Empires and Global Interconnections: The Rise and Fall of Qing Dynasty & Russian Empires", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-qing.avif" },



    // add more objects for more subtopics, with corresponding years
]);




  const CustomMenuItem = ({ completed, children, ...other }) => {
    return (
      <MenuItem {...other}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{ style: { textDecoration: 'line-through', color: 'green' } }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={event => setSelectedYear(event.target.value)}
      >
        {[...Array(11).keys()].map((_, index) => (
          <MenuItem value={index + 1} key={index}>
            Year {index + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );




  //Rendering of subtopic cards

  const renderSubtopicCards = () => {
    // Only get subTopics for the selected year
    const relevantSubTopics = subTopics.filter(subTopic => subTopic.year === Number(selectedYear));



    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                <FormControl fullWidth>
                  <Select defaultValue="" onChange={(event) => navigate(`/Classroom/${event.target.value}`)}>
                    <MenuItem value="" disabled>
                      Select a lesson
                    </MenuItem>
                    {loading && <MenuItem disabled>Loading...</MenuItem>}
                    {error && !lessonsBySubtopic[subTopic.id]?.length && <MenuItem disabled>Error: {error}</MenuItem>}
                    {lessonsBySubtopic[subTopic.id]?.map(lesson => {
  const isCompleted = completedLessons.includes(lesson.id);
  return (
    <CustomMenuItem key={lesson.id} value={lesson.id} completed={isCompleted}>
      {lesson.title}
    </CustomMenuItem>
  );
})}

                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
    
    
  }
  
  

if (!access_token) {
  console.warn("Missing access token. Redirecting to login.");
  navigate('/StudentLogin'); // Redirect to login if there's no access token
  return;
}


  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => { console.log("Clicked!"); navigate('/LessonSelect'); }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            History
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
    Select a year first.
</Typography>
<Typography variant="h5" align="center" color="text.secondary" paragraph>
    Don't be scared to drop down or move up years, there's value in practicing the basics, or going for a challenge!
</Typography>
<Typography variant="h5" align="center" color="text.secondary" paragraph>
    <b>Year Selector</b>
</Typography>

        <Typography variant="h7" align="center" color="text.secondary" paragraph>
(Note: This will default to the year of the last History lesson you completed, but feel free to change it whenever!)
        </Typography>
        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}> {/* This will add a margin-top of 3 spacing units */}
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
  
  
};

export default History;
