import React, { useEffect, useState } from 'react';
import useApi from '../Utils/useApi';
import {
  AppBar, Button, Toolbar, Typography, Container, Box,
  IconButton, Grid, Paper
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import useCountdownToSundayMidnight from '../Utils/CountdownToSundayMidnight';
import NotificationIcon from './NotificationIcon';
import UserGreeting from './UserGreeting';
import AvatarDisplayDash from '../Avatar/AvatarDisplayDash';
import AvatarIcon from '../Avatar/AvatarIcon';
import AvatarIconLB from '../Avatar/AvatarIconLB.js'; // Ensure this path is correctconst safeApiCall = async (apiCall) => {
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { setCache, getCache } from '../Utils/cacheUtils';
import AvatarIconLBFast from '../Avatar/AvatarIconLBFast';
import { CircularProgress } from '@mui/material';

const defaultTheme = createTheme();

const StudentDashboard = () => {
  const navigate = useNavigate();
  const [studentProgress, setStudentProgress] = useState({});
  const [lessonsProgress, setLessonsProgress] = useState([]);
  const student_id = localStorage.getItem('user_id');
  const access_token = localStorage.getItem('accessToken');
  const [currentLeague, setCurrentLeague] = useState("");
  const [leagueBadgeURL, setLeagueBadgeURL] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [earnedBadges, setEarnedBadges] = useState([]);
  const [availableBadges, setAvailableBadges] = useState([]);
  const [leagueTiers, setLeagueTiers] = useState([]);
  const countdown = useCountdownToSundayMidnight();
  const api = useApi();
  const [avatars, setAvatars] = useState({});
  const [loading, setLoading] = useState(true);


  const getCurrentZone = (rocketfuel, thresholds) => {
    if (rocketfuel < thresholds.demotion) return 'Demotion Zone';
    if (rocketfuel > thresholds.promotion) return 'Promotion Zone';
    return 'Neutral Zone';
  };

  const thresholds = {
    promotion: 1000,
    demotion: 100
  };

  const currentStudentEntry = leaderboard.find(entry => entry.user_id.toString() === student_id);
  const currentRocketfuel = currentStudentEntry ? currentStudentEntry.rocketfuel : 0;
  const pointsToPromotion = thresholds.promotion - currentRocketfuel;
  const pointsToStay = thresholds.demotion - currentRocketfuel;
  const [name, setName] = useState('');


  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('accessToken');
    if (user_id && access_token) {
      api.get(`/user/getname/${user_id}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        if (response.data.firstname) {
          setName(response.data.firstname);
        }
      })
      .catch(error => console.log(error));
    }
  }, []);

  const handleRowClick = (row) => {
    navigate(`/Classroom/${row.lesson_id}`);
  };

  useEffect(() => {
    if (!access_token) return;
  
    const fetchAPIs = async () => {
      try {
        // Check and fetch cached data
        const cachedBadges = getCache('badges');
        const cachedAvailableBadges = getCache('availableBadges');
        const cachedTiers = getCache('leagueTiers');
        const cachedLeaderboard = getCache('leaderboard');
  
        if (cachedBadges) setEarnedBadges(cachedBadges);
        if (cachedAvailableBadges) setAvailableBadges(cachedAvailableBadges);
        if (cachedTiers) setLeagueTiers(cachedTiers);
        if (cachedLeaderboard) {
          const filteredLeaderboard = cachedLeaderboard.filter(entry => entry.rocketfuel > 0); // Filter cached leaderboard
          setLeaderboard(filteredLeaderboard);
  
          // Preload avatars for the leaderboard
          const leaderboardUserIds = filteredLeaderboard.map(entry => entry.user_id);
          const cachedAvatars = sessionStorage.getItem('batch_avatars');
          if (cachedAvatars) {
            setAvatars(JSON.parse(cachedAvatars)); // Use cached avatars
          } else {
            const avatarResponse = await api.post('/avatar/users', { user_ids: leaderboardUserIds });
            if (avatarResponse?.data) {
              const avatarMap = avatarResponse.data.reduce((acc, avatarData) => {
                acc[avatarData.user_id] = avatarData.components.sort((a, b) => a.layer - b.layer);
                return acc;
              }, {});
              setAvatars(avatarMap); // Store preloaded avatars
              sessionStorage.setItem('batch_avatars', JSON.stringify(avatarMap)); // Cache avatars
            }
          }
        }
  
        const safeApiCall = async (apiCall) => {
          try {
            return await apiCall;
          } catch (error) {
            console.error('API Error:', error);
            return null; // Handle failure gracefully
          }
        };
  
        const [
          progressResponse,
          studentProgressResponse,
          leagueResponse,
          leaderboardResponse,
          badgesResponse,
          availableBadgesResponse,
          tiersResponse,
        ] = await Promise.all([
          safeApiCall(api.get(`/progress/student/${student_id}/last_completed_lessons`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          safeApiCall(api.get(`/progress/student-progress/${student_id}`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          safeApiCall(api.get(`/rfleague/get-current-league`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          !cachedLeaderboard && safeApiCall(api.get(`/rfleague/get-hierarchical-leaderboard`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          !cachedBadges && safeApiCall(api.get(`/rfleague/get-badges`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          !cachedAvailableBadges && safeApiCall(api.get(`/rfleague/get-available-badges`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
          !cachedTiers && safeApiCall(api.get(`/rfleague/get-league-tiers`, { headers: { 'Authorization': `Bearer ${access_token}` } })),
        ]);
  
        // Update state
        setLessonsProgress(progressResponse?.data || []);
        setStudentProgress(studentProgressResponse?.data || {});
        if (leagueResponse?.data) {
          const { league_name, imgURL } = leagueResponse.data;
          setCurrentLeague(league_name);
          setLeagueBadgeURL(imgURL);
        }
  
        // Cache and filter results for future use
        if (!cachedLeaderboard && leaderboardResponse) {
          const filteredLeaderboard = leaderboardResponse.data.leaderboard.filter(entry => entry.rocketfuel > 0); // Filter API response
          setCache('leaderboard', filteredLeaderboard, 10); // Cache filtered data
          setLeaderboard(filteredLeaderboard);
  
          // Preload avatars for the leaderboard
          const leaderboardUserIds = filteredLeaderboard.map(entry => entry.user_id);
          const avatarResponse = await safeApiCall(api.post('/avatar/users', { user_ids: leaderboardUserIds }));
          if (avatarResponse?.data) {
            const avatarMap = avatarResponse.data.reduce((acc, avatarData) => {
              acc[avatarData.user_id] = avatarData.components.sort((a, b) => a.layer - b.layer);
              return acc;
            }, {});
            setAvatars(avatarMap); // Store preloaded avatars
            sessionStorage.setItem('batch_avatars', JSON.stringify(avatarMap)); // Cache avatars
          }
        }
  
        if (!cachedBadges) setCache('badges', badgesResponse?.data?.badges || [], 10);
        if (!cachedAvailableBadges) setCache('availableBadges', availableBadgesResponse?.data?.badges || [], 10);
        if (!cachedTiers) setCache('leagueTiers', tiersResponse?.data?.league_tiers || [], 10);
      } catch (error) {
        console.log('Error fetching APIs:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchAPIs();
  }, [student_id, access_token]);
  
  

  const handleLogin = () => {
    window.location.href = "/StudentLogin";
  };

  const anonymizeUsername = (username, userId) => {
    // If the userId matches student_id, return the username as is
    if (userId.toString() === student_id) return username;
  
    // If there's no username, return an empty string
    if (!username) return '';
  
    // Otherwise, return the full username without anonymizing
    return username;
  };

  const columns = [
    {
      field: 'avatar',
      headerName: '',
      width: 70,
      renderCell: (params) => {
        const userId = params.row.user_id; // Get the user_id for this row
        return (
          <AvatarIconLBFast
            userIds={[userId]} // Only render this user's avatar
            avatars={avatars} // Pass preloaded avatars
          />
        );
      },
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'username',
      headerName: 'Student',
      flex: 1,
      cellClassName: (params) => (params.row.isStudent ? 'highlighted-student' : ''),
    },
    {
      field: 'rocketfuel',
      headerName: 'Rocketfuel',
      flex: 1,
    },
    {
      field: 'leagueBadge',
      headerName: 'League Badge',
      width: 100,
      renderCell: (params) => (
        <LazyLoadImage
          src={params.row.leagueBadge}
          alt="League Badge"
          effect="blur"
          style={{ width: '50px', height: '50px' }}
        />
      ),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];
  
  
  

  // Group and sort leaderboard by leagues
  const groupedLeaderboard = leaderboard.reduce((acc, entry) => {
    if (!acc[entry.league_name]) {
      acc[entry.league_name] = [];
    }
    acc[entry.league_name].push(entry);
    return acc;
  }, {});

  const sortedLeagues = leagueTiers
    .map(tier => tier.name)
    .filter(leagueName => groupedLeaderboard[leagueName] && groupedLeaderboard[leagueName].length > 0);

  if (!access_token) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/LessonSelect')}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Student Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Please log in to see your dashboard
            </Typography>
            <Button variant="contained" onClick={handleLogin}>Log in</Button>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/RLBlog')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {name}'s Dashboard
          </Typography>
          
          <Box flexGrow={1} /> {/* This pushes everything after it to the right */}
          <Box sx={{ mx: 2 }}>
            <Button variant="contained" color="success" onClick={() => navigate('/Assessments')}>
              Take an Assessment
            </Button>
          </Box>
          <UserGreeting />
          <IconButton onClick={() => navigate('/Avatar')} sx={{ ml: 2 }}>
            <AvatarIcon accessToken={access_token} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>

            {/* Grid for Your Progress and Your Earned Badges */}
            <Grid item xs={12} md={7}>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your Progress
              </Typography>
              {lessonsProgress.map((progress) => (
                <Paper key={progress.subject_id} style={{ marginBottom: '20px', padding: '16px' }}>
                  <Typography variant="h6">{progress.subject_name}</Typography>
                  <Typography variant="body1">Last Completed: {progress.last_completed_lesson_title}</Typography>
                  {progress.next_lesson_id && (
                    <>
                      <Typography variant="body1" style={{ marginTop: '10px' }}>Next Lesson: {progress.next_lesson_title}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => navigate(`/Classroom/${progress.next_lesson_id}`)}
                      >
                        Go to Next Lesson
                      </Button>
                    </>
                  )}
                </Paper>
              ))}
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your Earned Badges
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {availableBadges.map((badge) => {
                  const earned = earnedBadges.some(eBadge => eBadge.league_name === badge.league_name);
                  const badgeURL = earned ? badge.imgURL : "https://badges.ams3.cdn.digitaloceanspaces.com/nobadge.png";
                  return (
                    <div key={badge.league_name} style={{ margin: '15px 10px', textAlign: 'center' }}>
                      <LazyLoadImage
          src={badgeURL}
          alt={badge.league_name}
          effect="blur" // Add a blur effect while loading
          style={{ width: '150px', height: '150px', marginBottom: '10px' }}
        />
                      <Typography variant="body2">{badge.league_name}</Typography>
                    </div>
                  );
                })}
              </div>
            </Grid>
            {/* Grid item for Rocketfuel and Leaderboard */}
            <Grid item xs={12} md={5}>
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                  {currentLeague}
                </Typography>
                <div style={{ textAlign: 'center' }}>
                <LazyLoadImage
  src={leagueBadgeURL}
  alt="League Badge"
  effect="blur" // Add a blur effect while loading
  style={{ width: '300px', height: '300px' }}
/>
                </div>
                <AvatarDisplayDash userId={student_id} accessToken={access_token} style={{ width: '300px', height: '300px' }} />
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Make sure you've got all your rocketfuel stored before the clock strikes Midnight on Sunday!
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  League Update Countdown: <br /> {countdown}
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  This Week's Rocketfuel: <strong>{currentRocketfuel}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  You are in the: <strong>{getCurrentZone(currentRocketfuel, thresholds)}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points to stay in current league: <strong>{pointsToStay}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points for next promotion: <strong>{pointsToPromotion}</strong>
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Leaderboard
                </Typography>
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Earned RocketFuel updates every 5 minutes. Don't worry if you can't see it right away!
                </Typography>
                {sortedLeagues.map(leagueName => (
                  <React.Fragment key={leagueName}>
                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                      {leagueName} League
                    </Typography>
                    <DataGrid
                      autoHeight
                      autoWidth
                      pageSize={25}
                      rowsPerPageOptions={[10, 20, 50]}
                      rows={groupedLeaderboard[leagueName]?.map(entry => ({
                        ...entry,
                        id: entry.user_id,
                        username: anonymizeUsername(entry.username, entry.user_id),
                        isStudent: entry.user_id.toString() === student_id,
                        leagueBadge: entry.imgURL // Assuming the league badge URL is part of the entry
                      })) || []}
                      columns={columns}
                    />
                  </React.Fragment>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default StudentDashboard;
