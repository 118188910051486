// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .parent-table th, .parent-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .parent-table tr:hover {
    background-color: #f2f2f2;
  }
  
  .parent-table .parent-row {
    cursor: pointer;
  }
  
  .expanded-details {
    background-color: #f9f9f9;
  }
  
  .children-list {
    list-style: none;
    padding-left: 0;
  }
  
  .child-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .subscription-details {
    margin-left: 20px;
  }
  
  button {
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Utils/UserAdmin.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".parent-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .parent-table th, .parent-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .parent-table tr:hover {\n    background-color: #f2f2f2;\n  }\n  \n  .parent-table .parent-row {\n    cursor: pointer;\n  }\n  \n  .expanded-details {\n    background-color: #f9f9f9;\n  }\n  \n  .children-list {\n    list-style: none;\n    padding-left: 0;\n  }\n  \n  .child-item {\n    padding: 10px;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .subscription-details {\n    margin-left: 20px;\n  }\n  \n  button {\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
