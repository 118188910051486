import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './ChatApp.css'; // Add custom styles for the chat UI

const ChatApp = ({ closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isStudent, setIsStudent] = useState(false); // State to check if the user is a student
  const [currentUserId, setCurrentUserId] = useState(null);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    // Check user type whenever the component mounts or user details change
    const token = localStorage.getItem('accessToken');
    const userType = localStorage.getItem('usertype');
    const userId = localStorage.getItem('user_id');

    if (userType === 'student') {
      setIsStudent(true);
      setCurrentUserId(userId);
    } else {
      setIsStudent(false);
      setCurrentUserId(null);
      console.warn('Chat is only available for student accounts.');
      return; // Exit early if not a student
    }

    const socket = io('https://whale-app-6qyfq.ondigitalocean.app', {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    const fetchChatHistory = async () => {
      try {
        const response = await fetch('https://whale-app-6qyfq.ondigitalocean.app/get-recent-messages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        const formattedMessages = data.map(msg => ({
          ...msg,
          isSelf: String(msg.sender_id) === String(userId),
        }));

        setMessages(formattedMessages);
        scrollToBottom(); // Initial scroll to the bottom on load
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    };

    if (isStudent) {
      fetchChatHistory();
    }

    socket.on('receive-message', (msg) => {
      setMessages((prevMessages) => {
        const shouldScroll =
          chatWindowRef.current.scrollHeight - chatWindowRef.current.scrollTop ===
          chatWindowRef.current.clientHeight;

        const newMessages = [
          ...prevMessages,
          { ...msg, isSelf: String(msg.sender_id) === String(userId) }
        ];

        if (shouldScroll) {
          setTimeout(scrollToBottom, 100);
        }

        return newMessages;
      });
    });

    socket.on('message-flagged', (data) => {
      const systemMessage = {
        username: 'System',
        message: data.message,
        isSelf: true,
        isSystem: true,
      };

      setMessages((prevMessages) => [...prevMessages, systemMessage]);

      setTimeout(() => {
        setMessages((prevMessages) => prevMessages.filter(msg => msg !== systemMessage));
      }, 5000);
    });

    return () => {
      socket.disconnect();
    };
  }, [isStudent]); // Re-run the effect if `isStudent` changes

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    if (input.trim() && isStudent) {
      const token = localStorage.getItem('accessToken');
      const currentUsername = localStorage.getItem('username');

      const msg = {
        message: input,
        userId: currentUserId,
        username: currentUsername,
        sender_id: currentUserId,
      };

      const socket = io('https://whale-app-6qyfq.ondigitalocean.app/', {
        auth: {
          token: `Bearer ${token}`,
        },
      });

      socket.emit('send-message', msg);
      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  if (!isStudent) {
    return (
      <div className="chat-container">
        <div className="chat-header">
          <button onClick={closeChat} className="chat-close-button">X</button>
          <h4>Chat Unavailable</h4>
        </div>
        <div className="chat-window">
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            Chat is only available for student accounts.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-container">
      <div className="chat-header">
        <button onClick={closeChat} className="chat-close-button">X</button>
        <img
          src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RocketChatv1Logo.png"
          alt="RocketChat v1"
          className="chat-header-logo"
        />
      </div>
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${msg.isSelf ? 'chat-message-self' : 'chat-message-other'} ${msg.isSystem ? 'chat-message-system' : ''}`}
            style={{ color: msg.isSystem ? 'red' : 'inherit' }}
          >
            <strong>{msg.username}:</strong> {msg.message}
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
          onKeyPress={handleKeyPress}
        />
        <button onClick={sendMessage} className="chat-send-button">Send</button>
      </div>
    </div>
  );
};

export default ChatApp;
