import Phaser from 'phaser';
import { scoreManager } from './ScoreManager';

class GameScene7 extends Phaser.Scene {
  constructor() {
    super({ key: 'GameScene7' });
  }

  init(data) {
    console.log('GameScene init');
    this.level = data.level || 1;

    // Define enemy names for each level
    this.enemyNames = {
      1: 'Grumblebum',
      2: 'Snarlsnout',
      3: 'Bugbear',
      4: 'Riverscale',     // Level 4: River Crossing
      5: 'Wraithvine',     // Level 5: Mystic Ruins
      6: 'Darkbark',       // Level 6: Enchanted Forest
      7: 'Stoneclaw',      // Level 7: Mountain Pass
      8: 'Gatekeeper',     // Level 8: Castle Gate
      9: 'Shadowfang',     // Level 9: Castle Courtyard
      10: 'Malvorius',     // Level 10: Throne Room (Final Boss)
      // Add more names as needed for other levels
    };
  }

  preload() {
    console.log('GameScene preload');

    // Load the background image, Lexica sprite, and the enemy sprite
    this.load.image(`level${this.level}bg`, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/level${this.level}bg.png`);
    this.load.image('lexica', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/lexicafight.png');
    this.load.image(`enemy${this.level}`, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/enemy${this.level}.png`);
    this.load.image('lightBall', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/spell.png');

    // Load the tick image for the confirm button
    this.load.image('tick', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/images/tick.png');

    // Preload the sound effect for Lexica's spell
    this.load.audio('lexicaSpellSound', 'https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/audio/mixkit-fireball-spell-1347.wav');

    // Load the words data and audio files
    const wordsData = this.cache.json.get('words');
    const ageGroup = this.registry.get('selectedAgeGroup');  // Get the selected age group from registry
    if (wordsData && ageGroup) {
      this.words = wordsData.ageGroups[ageGroup][this.level.toString()];
      console.log('Loaded words before shuffle:', this.words);

      // Shuffle the words array
      this.shuffleArray(this.words);
      
      console.log('Loaded words after shuffle:', this.words);

      this.words.forEach(word => {
        this.load.audio(word, `https://rl-spellbound.ams3.cdn.digitaloceanspaces.com/audio/${ageGroup}/level${this.level}/${word}.mp3`);
      });
    } else {
      console.error('Failed to load words data or age group not selected');
    }
  }

  create() {
    console.log('GameScene create');

    // Display the score
    // Create the score texts and set their depth to ensure they are on top of other elements
    this.levelScoreText = this.add.text(20, 720, `Level Score: 0`, { fontSize: '24px', fill: '#fff', backgroundColor: '#000' });
    this.levelScoreText.setDepth(100);  // Set the depth to ensure it appears on top

    this.overallScoreText = this.add.text(20, 750, `Overall Score: ${scoreManager.getOverallScore()}`, { fontSize: '24px', fill: '#fff', backgroundColor: '#000' });
    this.overallScoreText.setDepth(100);  // Set the depth to ensure it appears on top


    // Fade out the music when the scene starts
    const music = this.registry.get('mainMenuMusic');
    if (music) {
      this.tweens.add({
        targets: music,
        volume: 0,
        duration: 1000,  // 1 second fade out
      });
    }

    // Add the dynamically loaded background image to the scene
    this.add.image(600, 400, `level${this.level}bg`).setOrigin(0.5, 0.5);

    if (!this.words) {
      console.error('No words loaded for this level');
      return;
    }

    this.wordIndex = 0;
    this.currentWord = this.words[this.wordIndex];
    this.playerInput = '';

    this.add.text(600, 50, `Level ${this.level}: Type the word you hear to cast a spell!`, { fontSize: '32px', fill: '#fff' }).setOrigin(0.5);

    const hearWordButton = this.add.text(600, 440, 'Hear Word', { fontSize: '32px', fill: '#fff', backgroundColor: '#000' }).setOrigin(0.5).setInteractive();
    hearWordButton.on('pointerdown', () => {
      this.playWordAudio(this.currentWord);
    });

    // Create a single input box to display the word being typed
    this.createSingleInputBox();

    // Initialize Lexica's health
    this.lexicaHealth = 100;
    this.createLexicaHealthBar();

    // Add the Lexica sprite and position it on the right side of the screen
    this.lexica = this.add.sprite(1000, 400, 'lexica').setOrigin(0.5, 0.5);

    // Add the enemy sprite and position it on the left side of the screen
    this.enemy = this.add.sprite(200, 400, `enemy${this.level}`).setOrigin(0.5, 0.3).setScale(0.65);

    // Initialize the enemy's health
    this.enemyHealth = 100;
    this.createEnemyHealthBar();

    // Create the on-screen keyboard, ensuring it is layered on top of the Lexica sprite
    this.createOnScreenKeyboard();

    // Create the confirm button
    this.createConfirmButton();

    // Enable keyboard input from the physical keyboard
    this.input.keyboard.on('keydown', this.handleKeyPress, this);
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  createSingleInputBox() {
    console.log('Creating single input box for word:', this.currentWord);

    // Position the input box just above the on-screen keyboard
    const startX = 600;
    const startY = 500;

    // Create a background box
    this.inputBox = this.add.rectangle(startX, startY, 500, 60, 0xffffff).setOrigin(0.5);

    // Create a text object inside the input box to display the player's input
    this.inputText = this.add.text(startX, startY, '', { fontSize: '32px', fill: '#000' }).setOrigin(0.5);
  }

  createLexicaHealthBar() {
    // Create a background bar (to show the max health)
    this.lexicaHealthBarBg = this.add.rectangle(1000, 120, 200, 40, 0xff0000).setOrigin(0.5);

    // Create the health bar that will decrease
    this.lexicaHealthBar = this.add.rectangle(1000, 120, 200, 40, 0x00ff00).setOrigin(0.5);

    // Add Lexica's name inside the health bar with black text
    this.lexicaHealthText = this.add.text(1000, 120, 'Lexica', { fontSize: '24px', fill: '#000' }).setOrigin(0.5);
  }

  createEnemyHealthBar() {
    // Create a background bar (to show the max health)
    this.enemyHealthBarBg = this.add.rectangle(200, 120, 200, 40, 0xff0000).setOrigin(0.5);

    // Create the health bar that will decrease
    this.enemyHealthBar = this.add.rectangle(200, 120, 200, 40, 0x00ff00).setOrigin(0.5);

    // Add the enemy's name inside the health bar with black text
    const enemyName = this.enemyNames[this.level] || 'Enemy';
    this.enemyHealthText = this.add.text(200, 120, enemyName, { fontSize: '24px', fill: '#000' }).setOrigin(0.5);
  }

  updateLexicaHealthBar() {
    // Update the width of the health bar based on Lexica's health
    const newWidth = (this.lexicaHealth / 100) * 200;
    this.lexicaHealthBar.width = newWidth;
  }

  updateEnemyHealthBar() {
    // Update the width of the health bar based on the enemy's health
    const newWidth = (this.enemyHealth / 100) * 200;
    this.enemyHealthBar.width = newWidth;
  }

  handleKeyPress(event) {
    if (event.key === 'Backspace') {
      this.playerInput = this.playerInput.slice(0, -1);
    } else if (event.key === 'Enter') {
      this.checkPlayerInput(); // Confirm the input when Enter is pressed
    } else if (event.key.length === 1) {
      this.playerInput += event.key.toLowerCase();
    }

    this.updateInputBox();
  }

  updateInputBox() {
    this.inputText.setText(this.playerInput);
  }

  checkPlayerInput() {
    // If the input box is empty, do not proceed with the attack
    if (this.playerInput.trim() === '') {
      return;
    }
  
    // Normalize both the player's input and the current word to lowercase
    const normalizedInput = this.playerInput.trim().toLowerCase();
    const normalizedWord = this.currentWord.trim().toLowerCase();
  
    if (normalizedInput === normalizedWord) {
      this.castSpell();
    } else {
      this.showCorrectSpelling(); // Show the correct spelling of the word
      this.playerInput = '';
      this.updateInputBox();
      this.reduceLexicaHealth(); // Reduce Lexica's health if the word is incorrect
    }
    this.moveToNextWord(); // Prepare the next word but do not play it automatically
  }

  showCorrectSpelling() {
    // Display the correct word on the screen with a larger, bold, white font, and a black stroke
    const correctSpellingText = this.add.text(600, 450, `Correct Spelling: ${this.currentWord}`, {
      fontSize: '48px',         // Larger font size
      fill: '#ffffff',          // White text color
      fontWeight: 'bold',       // Bold font weight
      stroke: '#000000',        // Black stroke color
      strokeThickness: 6,       // Thickness of the stroke
      fontFamily: 'Arial',      // Use a font that supports bold weight
      align: 'center'
    }).setOrigin(0.5);
  
    // Remove the correct spelling text after 3 seconds
    this.time.delayedCall(3000, () => {
      correctSpellingText.destroy();
    });
  }

  moveToNextWord() {
    // Move to the next word in the array, looping back to the start if necessary
    this.wordIndex = (this.wordIndex + 1) % this.words.length;
    this.currentWord = this.words[this.wordIndex];
    // No automatic audio playback here.
    // The player must press the "Hear Word" button to hear the next word.
  }

  reduceLexicaHealth() {
    console.log('Lexica takes damage!');

        // Deduct points for an incorrect word
        const penaltyPoints = 100;  // Points deducted for each incorrect word
        scoreManager.addPoints(-penaltyPoints);  // Deduct points by passing a negative value
        this.updateScoreDisplay();  // Update the on-screen score display

    // Play the same sound effect used for Lexica's spell when Lexica is hit
    this.sound.play('lexicaSpellSound');

    // Create the light ball at the enemy's position and scale it down
    const lightBall = this.add.sprite(this.enemy.x, this.enemy.y, 'lightBall').setOrigin(0.5).setScale(0.5); // Scale down to 50%

    // Animate the light ball to fly to Lexica
    this.tweens.add({
      targets: lightBall,
      x: this.lexica.x,  // Move to Lexica's x position
      y: this.lexica.y,  // Move to Lexica's y position
      duration: 1200,   // Same duration as before
      ease: 'Power1',   // Ease function for smooth movement
      angle: 360,       // Rotate the light ball as it moves
      onComplete: () => {
        // Destroy the light ball sprite once it reaches Lexica
        lightBall.destroy();

        // Reduce Lexica's health after the spell hits
        this.lexicaHealth -= 10; // Reduce health by 10 for each incorrect word
        this.updateLexicaHealthBar();
        this.shakeSprite(this.lexica); // Shake Lexica's sprite

        // Check if Lexica's health has reached zero
        if (this.lexicaHealth <= 0) {
          this.gameOver(); // Trigger game over if health is zero
        }
      }
    });
  }

  updateScoreDisplay() {
    this.levelScoreText.setText(`Level Score: ${scoreManager.getLevelScore()}`);
    this.overallScoreText.setText(`Overall Score: ${scoreManager.getOverallScore()}`);
  }

  reduceEnemyHealth() {
    this.enemyHealth -= 20; // Reduce health by 20 for each correct word
    this.updateEnemyHealthBar();
    this.shakeSprite(this.enemy); // Shake the enemy's sprite

    // Check if the enemy's health has reached zero
    if (this.enemyHealth <= 0) {
      this.enemyDefeated(); // Trigger enemy defeated logic
    }
  }

  shakeSprite(sprite) {
    this.tweens.add({
      targets: sprite,
      x: sprite.x + 10,  // Move 10 pixels to the right
      duration: 50,  // Duration of each "shake" movement
      yoyo: true,  // Move back to the original position
      repeat: 3,  // Repeat the shake movement 3 times
      ease: 'Power1'
    });
  }

  enemyDefeated() {
    console.log('Enemy Defeated!');

    // Now accumulate the level score into the overall score
    scoreManager.accumulateLevelScore();
    this.updateScoreDisplay(); 
  
    // Fade in the music when the level ends
    const music = this.registry.get('mainMenuMusic');
    if (music) {
      this.tweens.add({
        targets: music,
        volume: 1,
        duration: 1000,  // 1 second fade in
      });
    }
  
    // Add any logic here for when the enemy is defeated (e.g., proceed to next level)
    this.time.delayedCall(1000, () => {  // Add a delay to match the fade in duration
      if (this.level < 10) {
        this.scene.start('LevelIntroScene', { level: this.level + 1 });
      } else {
        this.scene.start('MainMenuScene');
      }
    });
  }

  gameOver() {
    console.log('Game Over! Lexica has been defeated!');
  
    // Fade in the music when the game is over
    const music = this.registry.get('mainMenuMusic');
    if (music) {
      this.tweens.add({
        targets: music,
        volume: 1,
        duration: 1000,  // 1 second fade in
      });
    }
  
    // Display "Game Over" message
    this.add.text(600, 300, 'Game Over', { fontSize: '64px', fill: '#ff0000' }).setOrigin(0.5);
  
    // Optionally, restart the game after a delay
    this.time.delayedCall(3000, () => {
      this.scene.start('MainMenuScene');
    });
  }

  createOnScreenKeyboard() {
    console.log('Creating on-screen keyboard');
    
    // Define the QWERTY keyboard layout as rows of keys
    const rows = [
        'QWERTYUIOP'.split(''),
        'ASDFGHJKL'.split(''),
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '←'] // Last row includes the backspace key (←)
    ];

    const keyWidth = 50;  // Width of each key
    const keyHeight = 50; // Height of each key
    const startX = 400;   // Adjusted to be more centered
    const startY = 600;   // Position just above the bottom of the screen

    // Iterate through each row to create the keys
    rows.forEach((row, rowIndex) => {
        const rowLength = row.length;
        const rowStartX = startX + (500 - (rowLength * (keyWidth + 10))) / 2; // Center the row

        row.forEach((key, index) => {
            const x = rowStartX + index * (keyWidth + 10);
            const y = startY + rowIndex * (keyHeight + 10);

            // Add rounded rectangle background with transparency
            const keyBackground = this.add.graphics();
            keyBackground.fillStyle(0xffffff, 0.5);  // Set transparency here (0.5)
            keyBackground.fillRoundedRect(x - keyWidth / 2, y - keyHeight / 2, keyWidth, keyHeight, 15);

            // Add text for each key
            const keyText = this.add.text(x, y, key, {
                fontSize: '32px',
                fill: '#000',
                fontFamily: 'Cartoon', // Replace with your desired font
            }).setOrigin(0.5); // Center the text

            // Make the background and text interactive
            keyBackground.setInteractive(new Phaser.Geom.Rectangle(x - keyWidth / 2, y - keyHeight / 2, keyWidth, keyHeight), Phaser.Geom.Rectangle.Contains);
            keyBackground.on('pointerdown', () => {
                if (key === '←') {
                    this.playerInput = this.playerInput.slice(0, -1);  // Handle backspace
                } else {
                    this.playerInput += key.toLowerCase();
                }
                this.updateInputBox();
            });

            keyText.setInteractive();
            keyText.on('pointerdown', () => {
                if (key === '←') {
                    this.playerInput = this.playerInput.slice(0, -1);  // Handle backspace
                } else {
                    this.playerInput += key.toLowerCase();
                }
                this.updateInputBox();
            });

            // Group the background and text in a container for easier manipulation
            this.add.container(0, 0, [keyBackground, keyText]);
        });
    });
}

  createConfirmButton() {
    // Position the confirm button near the input box
    const buttonX = 870;  // Adjust X position as needed
    const buttonY = 495;  // Same Y position as input box

    // Add the tick button sprite
    const confirmButton = this.add.image(buttonX, buttonY, 'tick').setOrigin(0.5).setInteractive();

    // Handle the click event for the confirm button
    confirmButton.on('pointerdown', () => {
      this.checkPlayerInput(); // Confirm the input when the button is clicked
    });
  }

  castSpell() {
    const points = 100;  // Example points awarded per correct word
    scoreManager.addPoints(points);  // Only add to level score during gameplay
    this.updateScoreDisplay(); 

    // Play the sound effect for Lexica's spell
    this.sound.play('lexicaSpellSound');

    // Create the light ball at Lexica's position and scale it down
    const lightBall = this.add.sprite(this.lexica.x, this.lexica.y, 'lightBall').setOrigin(0.5).setScale(0.5); // Scale down to 50%

    // Animate the light ball to fly to the enemy
    this.tweens.add({
      targets: lightBall,
      x: this.enemy.x,  // Move to enemy's x position
      y: this.enemy.y,  // Move to enemy's y position
      duration: 1200,   // Increased duration to slow down the animation (1.5 seconds)
      ease: 'Power1',   // Ease function for smooth movement
      angle: 360,       // Rotate the light ball as it moves
      onComplete: () => {
        // Destroy the light ball sprite once it reaches the enemy
        lightBall.destroy();

        // Reduce the enemy's health after the spell hits
        this.reduceEnemyHealth();
      }
    });

    // Continue with the game logic after casting the spell
    this.wordIndex = (this.wordIndex + 1) % this.words.length;
    this.currentWord = this.words[this.wordIndex];
    this.playerInput = '';
    this.updateInputBox(); // Clear the input box for the new word

    // Do not automatically play the next word here.
  }

  updateHealth() {
    // Ensure that healthText is initialized before using it
    if (this.healthText) {
      this.healthText.setText(`Boss Health: ${this.bossHealth}`);
    }
  }

  playWordAudio(word) {
    console.log('Playing word audio:', word);
    if (this.sound.context.state === 'suspended') {
      this.sound.context.resume().then(() => {
        this.sound.play(word);
      });
    } else {
      this.sound.play(word);
    }
  }
}

export default GameScene7;
