import React, { useState, useEffect, useRef } from 'react';
import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  Grid,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Switch,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import PaymentForm from './PaymentForm';
import useApi from '../Utils/useApi';
import './Checkout.css';

const defaultTheme = createTheme();

const Checkout = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState(new Set());
  const [studentSubscriptions, setStudentSubscriptions] = useState({});
  const [selectedNumberOfStudents, setSelectedNumberOfStudents] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [requestReference, setRequestReference] = useState('');
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [discountCode, setDiscountCode] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [autoRenew, setAutoRenew] = useState(true); // Default to auto-renew enabled
  const api = useApi();
  const isFetched = useRef(false);

  const fixedPrices = {
    '1 Student': { Month: 20, Year: 149 },
    '2 Students': { Month: 30, Year: 179 },
    'Up to 5 Students': { Month: 40, Year: 199 },
  };

  const studentOptions = [
    { label: 'Single Student', detail: '1 Student', id: '1 Student' },
    { label: 'Duo', detail: '2 Students', id: '2 Students' },
    { label: 'Family Pack', detail: 'Up to 5 Students', id: 'Up to 5 Students' },
  ];

  const handleRegister = () => {
    window.location.href = "/StudentRegistration";
  };

  const getStudentLimit = () => {
    switch (selectedNumberOfStudents) {
      case '1 Student':
        return 1;
      case '2 Students':
        return 2;
      case 'Up to 5 Students':
        return 5;
      default:
        return Infinity;
    }
  };

  useEffect(() => {
    const fetchStudentsAndSubscriptions = async () => {
      const parent_id = localStorage.getItem('user_id');
      if (!parent_id) {
        console.error('No parent ID found');
        return;
      }

      try {
        const res = await api.get(`/user/students/${parent_id}`);
        setStudents(res.data);

        const subscriptionPromises = res.data.map((student) =>
          api.get(`/subscription/subscription/user/${student.id}`)
        );
        const subscriptionResults = await Promise.all(subscriptionPromises);

        const subscriptionData = {};
        subscriptionResults.forEach((result, index) => {
          subscriptionData[res.data[index].id] = result.data;
        });

        setStudentSubscriptions(subscriptionData);
        isFetched.current = true;
      } catch (err) {
        console.error('Error fetching students or subscriptions:', err);
      }
    };

    if (!isFetched.current) {
      fetchStudentsAndSubscriptions();
    }
  }, [api]);

  const handleSelectNumberOfStudents = (option) => {
    setSelectedNumberOfStudents(option);
    setSelectedSubscription(null);
    setSelectedStudents(new Set());

    const options = {
      '1 Student': [
        { id: 'Month', title: '1 Month', price: 20 },
        { id: 'Year', title: '1 Year', price: 149 }
      ],
      '2 Students': [
        { id: 'Month', title: '1 Month', price: 30 },
        { id: 'Year', title: '1 Year', price: 179 }
      ],
      'Up to 5 Students': [
        { id: 'Month', title: '1 Month', price: 40 },
        { id: 'Year', title: '1 Year', price: 199 }
      ],
    };

    setSubscriptionOptions(options[option] || []);
  };

  useEffect(() => {
    if (selectedNumberOfStudents && selectedSubscription) {
      const prices = fixedPrices[selectedNumberOfStudents];
      let price = 0;
      switch (selectedSubscription) {
        case 'Month':
          price = prices.Month;
          break;
        case 'Year':
          price = prices.Year;
          break;
        default:
          console.error("Invalid subscription ID");
      }
      const finalPrice = isValidCode && selectedSubscription === 'Year'
        ? Math.floor(price * 0.9) // 10% discount for annual subscriptions
        : price;
      setSelectedPrice(price);
      setDiscountedPrice(finalPrice);
    }
  }, [selectedNumberOfStudents, selectedSubscription, isValidCode]);

  const handleSelectSubscription = (subscriptionId) => {
    setSelectedSubscription(subscriptionId);
  };

  const handleStudentSelectionChange = (studentId) => {
    const newSelection = new Set(selectedStudents);
    if (newSelection.has(studentId)) {
      newSelection.delete(studentId);
    } else {
      if (newSelection.size < getStudentLimit()) {
        newSelection.add(studentId);
      } else {
        console.log("Selection limit reached.");
      }
    }
    setSelectedStudents(newSelection);
  };

  const handleApplyDiscount = () => {
    if (discountCode.toLowerCase() === 'l30n15th3m05th@nds0m3ofAllth3d3v3l0pers' && selectedSubscription === 'Year') {
      setIsValidCode(true);
      const discount = selectedPrice * 0.1; // 10% discount
      setDiscountedPrice(Math.floor(selectedPrice - discount));
    } else {
      setIsValidCode(false);
      setDiscountedPrice(selectedPrice);
      alert("Invalid discount code or not applicable to the selected subscription.");
    }
  };
  
  

  const handleBuyNow = async () => {
    const selectedStudentIds = Array.from(selectedStudents);
    if (selectedStudentIds.length === 0 || !selectedSubscription) {
      alert("Please select at least one student and a subscription.");
      return;
    }

    try {
      const postData = {
        child_ids: selectedStudentIds.join(','),
        subscription_type: selectedSubscription,
        amount: discountedPrice,
        auto_renew: autoRenew,
      };

      const response = await api.post('/payment/pending-subscriptions', postData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
      });

      const { request_reference } = response.data;
      if (request_reference) {
        setRequestReference(request_reference);
      } else {
        throw new Error('No request reference returned');
      }
    } catch (error) {
      console.error('Failed to create pending subscription:', error);
      alert("Failed to initiate payment process. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh', padding: 0 }}>
        <Grid item xs={false} sm={4} md={7} className="backgroundImage" />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5">Select Number of Students</Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              {studentOptions.map((option) => (
                <Grid item xs={12} sm={6} md={4} key={option.id}>
                  <Card variant="outlined" sx={{ backgroundColor: selectedNumberOfStudents === option.id ? 'lightgray' : 'white' }}>
                    <CardActionArea onClick={() => handleSelectNumberOfStudents(option.id)}>
                      <CardContent>
                        <Typography variant="h5" component="div">{option.label}</Typography>
                        <Typography variant="body2">{option.detail}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {selectedNumberOfStudents && (
              <>
                <Typography component="h2" variant="h5" sx={{ mt: 4 }}>Select Subscription Length</Typography>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                  {subscriptionOptions.map((option) => (
                    <Grid item xs={12} sm={6} md={4} key={option.id}>
                      <Card variant="outlined" sx={{ backgroundColor: selectedSubscription === option.id ? 'lightgray' : 'white' }}>
                        <CardActionArea onClick={() => handleSelectSubscription(option.id)}>
                          <CardContent>
                            <Typography variant="h5" component="div">{option.title}</Typography>
                            <Typography variant="body2">£{option.price}</Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {selectedSubscription && (
              <>
                <Typography variant="h6" sx={{ mt: 4 }}>Select Students (up to {getStudentLimit()})</Typography>
                <Grid container justifyContent="center" sx={{ mt: 4 }}>
                  <Grid item xs={12} sm={6} md={4}>
                  {students.map((student) => {
  // Retrieve subscription status if you want to display it
  const hasActiveSubscription = studentSubscriptions[student.id]?.some(
    (sub) => sub.status === 'active'
  );

  return (
    <FormControlLabel
      key={student.id}
      control={
        <Switch
          checked={selectedStudents.has(student.id)}
          onChange={() => handleStudentSelectionChange(student.id)}
          disabled={
            !selectedStudents.has(student.id) && selectedStudents.size >= getStudentLimit()
          }
        />
      }
      label={`${student.firstname} ${student.lastname}`}
      sx={{
        justifyContent: 'flex-start',
        display: 'flex',
        width: '100%',
      }}
    />
  );
})}

                  </Grid>
                </Grid>
              </>
            )}

            {/* Auto-renew toggle switch */}
            {selectedSubscription && (
              <FormControlLabel
                control={
                  <Switch
                    checked={autoRenew}
                    onChange={() => setAutoRenew(!autoRenew)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1">
                    Auto-renew subscription (You can cancel anytime)
                  </Typography>
                }
                sx={{ mt: 2 }}
              />
            )}

            <Button 
              color="inherit"
              variant="outlined"
              onClick={handleRegister} 
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              Register More Students
            </Button>

            <Typography component="h2" variant="h5" sx={{ mt: 4 }}>Enter Voucher Code</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <TextField
                label="Discount Code"
                variant="outlined"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                sx={{ mr: 2 }}
              />
              <Button variant="contained" color="primary" onClick={handleApplyDiscount}>
                Apply
              </Button>
            </Box>

            <Typography variant="h6" sx={{ mt: 4 }}>Total Price: £{discountedPrice}</Typography>
            {selectedSubscription && selectedStudents.size > 0 && !requestReference && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleBuyNow}
                sx={{ mt: 3 }}
              >
                Buy Now
              </Button>
            )}

            {requestReference && (
              <PaymentForm
                domain="https://payments.securetrading.net"
                amount={discountedPrice}
                currency="GBP"
                requestReference={requestReference}
                autoRenew={autoRenew}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Checkout;
